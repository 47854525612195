import { useState, lazy, useEffect } from "react";
import {
  Card,
  Switch,
  Tooltip,
  notification,
  Radio,
  message,
  Table,
  Tabs,
  Button,
  Modal,
  Input,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  OrderedListOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import { putData } from "../../action";
import { Route, Link } from "react-router-dom";
import { replace, set } from "lodash";
import FilterTable from "../../components/Table";

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [phoneDIDPermissions, setPhoneDIDPermissions] = useState("");
  const [loading, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  
  const [dataSource2, setDataSource2] = useState()

  const deleteNumber = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } =
          await Request.deleteNumbers(fields?.id);
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "success",
          });
          apiFunction();
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };

  let Send = (id) => {
    dispatch(putData(id));
  };


  useEffect(() => {
    currentUser?.message.find((key, val) => {
      if (key.permissionType == "Phone Numbers") {
        setPhoneDIDPermissions(key);
      }
    });
  }, []);

  const setShowModalFxn = (record) => {
    dispatch(getPushPathWrapper("editDID", record));
    // request
  };



  const onChange = async (val, id) => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.updateNumber(
      id,
      { active: val }
    );
    setLoader(false);
    if (success) {
      notification.success({
        message: message || "success",
      });
      apiFunction();
    } else {
      notification.error({
        message: message || "Failed",
      });
    }
  };
  const show = async (nid) => {
    console.log(nid, "HELLO")
    setLoader(true);
    let { success, message, data } = await Request.numberDetails(
      { "id": nid }
    );
    setLoader(false);
   
    if (success) {
      setShowModal(true)
     
setDataSource2(data)
console.log(dataSource2,"GATEWAY")
     
      notification.success({
        message: message || "success",
      });
      apiFunction();
    } else {
      notification.error({
        message: message || "Failed",
      });
    }

  }
  let SendData = (id) => {
    dispatch(putData(id));
  };

  const columns = [
    {
      title: "Phone Number",
      dataIndex: "phonenumber",
      key: "phonenumber",
      render: (number, record) => {
        return (
          <>

            <a onClick={() => show(record.id)} >
              {number}
            </a>
          </>
        );
      },
    },
    {
      title: "Phone Code",
      dataIndex: "phonecode",
      key: "phonecode",
      search: true,
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Province",
      dataIndex: "province",
      key: "province",
    },
    {
      title: "Customer Name",
      dataIndex: "customername",
      key: "customername",
      render: (value, record) => {
        return (
          <>
            <Link
              to="SearchedUser/"
              onClick={() => SendData(record.userid?.id)}
            >
              {record?.userid?.auth?.firstname
                ? record?.userid?.auth?.firstname
                : " "}
              { }
              {record?.userid?.auth?.lastname
                ? record?.userid?.auth?.lastname
                : " "}
            </Link>
          </>
        );
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (val, record) => {
        return (
          <Switch checked={val} onChange={(e) => onChange(e, record?.id)} />
        );
      },
    },

    // {
    //   title: 'Created At',
    //   dataIndex: 'createdon',
    //   key: 'createdon',
    //   render: (val, r) => {
    //     return <Tag>{moment(val).format('DD-MM-YYYY')}</Tag>
    //   }
    // },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 180,
      render: (val, record) => {
        return (
          <div className="flex">
            <div>
              {phoneDIDPermissions?.deleteStatus ? (
                <Tooltip title="Delete Number">
                  <DeleteOutlined
                    style={{ color: "red", marginRight: 10, fontSize: 20 }}
                    onClick={() => deleteNumber(record)}
                  />
                </Tooltip>
              ) : (
                <></>
              )}

              {phoneDIDPermissions?.updateStatus ? (
                <Tooltip title="Update Number">
                  <EditOutlined
                    style={{ color: "black", fontSize: 20 }}
                    onClick={() => setShowModalFxn(record)}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      },
    },
  ];
  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.getAllNumbers({
      customdid: true,
    });
    setLoader(false);
    if (success) {
      setDataSource(() => data);
    } else {
      setDataSource(() => []);

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  };
  useEffect(() => {
    apiFunction();
  }, [phoneDIDPermissions?.listStatus]);
  return (
    <div>
      <div className="flex jcsb marginRightLarge">
        <div>
          {/* <Input
          placeholder="Search"
          suffix={
              <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          }
          style={{borderRadius:'4px'}}
        /> */}
        </div>
        {phoneDIDPermissions?.deleteStatus ? (
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            onClick={() => dispatch(getPushPathWrapper("AllNumber.addDID"))}
          >
            Add Number
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="marginTop">
        {phoneDIDPermissions?.listStatus && (
          <FilterTable
            bordered
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            scroll={{ x: 'auto' }}
          />
        )}
      </div>
      <div>
        <Modal width={1000} visible={showModal}
          destroyOnClose={true}
          onCancel={() => {
            setShowModal(false)
          }}
          footer={null}>
          <div className="recording"><h3><b>NUMBER DETAILS :</b></h3></div>
          <div className="marginTop">
            <ul
              className="CustomerDetails"
            >
              <li>
                <b> Country : </b>{dataSource2?.country}( <b> Phone Code : </b>{dataSource2?.phonecode})
              </li>
              <li>
                <b> Phone Number : </b>{dataSource2?.phonenumber}
              </li>

              <li>
                <b>Number Type : </b>{dataSource2?.tollfree ? "TollFree" : "DID"}
              </li>
              <li>
                <b>Allocated To : </b>  <Link
                to={`SearchedUser`} 
                onClick={() => Send(dataSource2?.userid?.id)}
                >
                {dataSource2?.userid?.auth?.firstname} {dataSource2?.userid?.auth?.lastname}
            </Link>
              </li>
              <li>
                <b> Number Added On : </b> {moment.utc(dataSource2?.purchasedbyuseron).format("MMMM Do YYYY")}
              </li>
              <li></li>
            </ul>
          </div>


          <div className="recording"><h3><b>SERVER DETAILS :</b></h3></div>
          <div className="marginTop">
            <ul
             className="CustomerDetails"
             >
              <li >
                <b> Registration Type : </b>{dataSource2?.gateway?.method == "ip" ? "IP" : "Username"}
              </li>

               {dataSource2?.gateway?.method  == "ip" ?
            (<> 
            <li>
            <b>Server IP : </b>
                {dataSource2?.gateway?.dns == null ? 
                dataSource2?.gateway?.ip
                :
                (<>{dataSource2?.gateway?.ip}(DNS:{dataSource2?.gateway?.dns})</>)}
                
              </li>
              <li>
                <b> Port : </b>{dataSource2?.gateway?.port}
              </li>
              </>)
              : 
              (<>
             
                <li>
                <b>Server IP : </b>
                {dataSource2?.gateway?.dns == null ? 
                dataSource2?.gateway?.ip
                :
                (<>{dataSource2?.gateway?.ip}(DNS:{dataSource2?.gateway?.dns})</>)}
              </li>
              <li>
                <b> Port : </b>{dataSource2?.gateway?.port}
              </li>
              <li><b>Username :</b> {dataSource2?.gateway?.username}</li>
                <li><b>Password : </b>{dataSource2?.gateway?.password}</li>
              <li></li>
              </>)}
             

              </ul> 
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default HomeView;
