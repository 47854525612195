import { useState, lazy, useEffect, useMemo, useCallback } from "react";
import {
  Card,
  Modal,
  Tooltip,
  notification,
  Radio,
  message,
  Table,
  Tabs,
  Button,
  Input,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import FilterTable from "../../components/Table";
import _ from "lodash";
import {
  OrderedListOutlined,
  AudioOutlined,
  CustomerServiceOutlined,
  SearchOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import { all } from "async";

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [carriersPermissions, setCarriersPermissions] = useState("");
  const [loading, setLoader] = useState(false);
  const [deptId, setDeptId] = useState("");
  const [showModal, setShowModal] = useState(false);


  const customerList = useSelector((state) => (state.global.customerList
  ));

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  useEffect(() => {
    idToCustomer();

    currentUser?.message.find((key, val) => {
      if (key.permissionType == "Carriers") {
        setCarriersPermissions(key);
      }
    });
  }, []);


  const deleteQueue = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete Queue",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } = await Request.deleteQueue(
          { department: fields?.id }
        );
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "success",
          });
          apiFunction();
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };
  const setShowModalOfProps = (filter) => {
    setShowModal(filter);
    apiFunction();
  };
  const setShowModalFxn = (record) => {
    setShowModal(true);
    setDeptId(record);
    // request
  };
  const deleteCarrier = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } =
          await Request.deleteCarriers(fields?.id);
        setLoader(false);
        apiFunction();
        if (success) {
          notification.success({
            message: message || "Failed",
          });
        } else {
          notification.error({
            message: message || "Failed",
            description: JSON.stringify(data)
              .replace("[", "")
              .replace("{", "")
              .replace("]", "")
              .replace("}", ""),
          });
        }
      },
    });
  };

  const editDept = (record) => {
    dispatch(getPushPathWrapper("editCarrier", record));
  };

  const idToCustomer = async () => {
    let { user, token, success, message, data } = await Request.allCustomer();
    console.log({ data, success, message })
    if (success) {
      dispatch({ type: "CUSTOMER_LIST", payload: data });
    } else {
      dispatch({ type: "CUSTOMER_LIST", payload: [] });
    }

  }


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Prefix",
      dataIndex: "prefix",
      key: "prefix",
      search: true,
    },
    // {
    //   title: "Locations",
    //   dataIndex: "locations",
    //   key: "locations",
    //   render: (val, r) => {
    //     return (
    //       <>
    //         {_.map(val, (value) => {
    //           return <Tag>{value}</Tag>;
    //         })}
    //       </>
    //     );
    //   },
    // },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (val, r) => {
        const customerName = customerList.filter(data => {
          return data.id === r.customer;
        })
        const customerListName = customerList.filter(data => {
          return r.listOfCustomer?.includes(data.id);
        })

        return (
          r.customerspecific ?
            <>
              {
                r.customer ? <Tag style={{margin: "2px"}}>{customerName[0]?.auth?.firstname}</Tag> : _.map(customerListName, customer => {

                  return <Tag style={{margin: "2px"}}>{customer?.auth?.firstname}</Tag>
                })
              }
            </> : " "
        );
      },
    },
    {
      title: "Customer Specific",
      dataIndex: "customerspecific",
      key: "customerspecific",
      render: (val, r) => {
        return (
          <Tag
            style={{ borderRadius: 30, width: 60, justifyContent: "center", margin: 'auto' }}
            color={val ? "#2fd838" : "#f50"}
          >
            {val ? "Yes" : "No"}
          </Tag>
        );
      },
    },

    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (val, r) => {
        return (
          <Tag
            style={{ borderRadius: 30, width: 60, justifyContent: "center", margin: "auto" }}
            color={val ? "#2fd838" : "#f50"}
          >
            {val ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    {
      title: "Locspecific",
      dataIndex: "locspecific",
      key: "locspecific",
      render: (val, r) => {
        return (
          <Tag
            style={{ borderRadius: 30, width: 60, justifyContent: "center", margin: 'auto' }}
            color={val ? "#2fd838" : "#f50"}
          >
            {val ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    // {
    //   title: "Created At",
    //   dataIndex: "createdon",
    //   key: "createdon",
    //   defaultSortOrder: "descend",
    //   sorter: (a, b) => a.createdon - b.createdon,
    //   render: (val, r) => {
    //     return <Tag>{moment.utc(val).format("DD-MM-YYYY")}</Tag>;
    //   },
    // },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (val, record) => {
        return (
          <div className="flex">
            {carriersPermissions?.deleteStatus ? (
              <div>
                <Tooltip title="Delete Carrier">
                  <DeleteOutlined
                    style={{ color: "red", fontSize: 20 }}
                    onClick={() => deleteCarrier(record)}
                  />
                </Tooltip>
              </div>
            ) : (
              <></>
            )}
            {carriersPermissions?.updateStatus ? (
              <div style={{ marginLeft: 10 }}>
                <Tooltip title="Edit Carrier">
                  <EditOutlined
                    style={{ color: "primary", fontSize: 20 }}
                    onClick={() => editDept(record)}
                  />
                </Tooltip>
              </div>
            ) : (
              <></>
            )}
            {record.ivrenabled || record?.musicenabled ? (
              <div style={{ marginLeft: 10 }}>
                <Tooltip title="Music & Ivr">
                  <CustomerServiceOutlined
                    style={{ color: "black", fontSize: 20 }}
                    onClick={() => setShowModalFxn(record)}
                  />
                </Tooltip>
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];
  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } =
      await Request.getAllCarriers();
    setLoader(false);
    if (success && data.length) {

      setDataSource(data);

    } else {
      setDataSource([]);

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  };
  useEffect(() => {
    apiFunction();
  }, [carriersPermissions?.listStatus]);
  return (
    <div>
      <div className="flex jcsb marginRight">
        <div>
          {/* <Input
          placeholder="Search"
          suffix={
            <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          }
          style={{ borderRadius: '4px' }}
        /> */}
        </div>
        {carriersPermissions?.createStatus ? (
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            onClick={() => dispatch(getPushPathWrapper("Server.addCarrier"))}
          >
            Add Carrier
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="marginTop">
        {carriersPermissions?.listStatus && (
          <FilterTable
            bordered
            dataSource={dataSource}
            size="large"
            columns={columns}
            loading={loading}
            scroll={{ x: 'auto' }}
          />
        )}
      </div>
    </div>
  );
};

export default HomeView;
