import react, { useEffect, useState } from 'react'
import { Form, Input, Button, notification, Card } from 'antd';
import FormItem from '../../components/FormItem'
import './styles.less'
import { useDispatch } from 'react-redux';
import Request from '../../request';
import _ from 'lodash'
import { getUrlParams } from '../../routes';
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};

const Demo = (props) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)
    const [params, setParams] = useState(false)
    const [countries, setCountries] = useState([])
    const getFunction = async () => {
        let { user, token, success, data } = await Request.getCountries()
        if (success) {
            setCountries(() => data)
        } else {
            setCountries(() => [])

        }
    }
    const onFinish = async (valData) => {
        valData = {
            auth: {
                ...valData
            }
        }
        if (params?.id) {
            setLoader(true)
            let { user, token, success, message, data } = await Request.updateAdmin(params?.id, { ...valData })
            // console.log(success, data, message)
            setLoader(false)
            if (success) {
                notification.success({
                    message: message
                })
            } else {
                notification.error({
                    message: message || "Failed"
                })
            }
        } else {
            setLoader(true)
            let { user, token, success, message, data } = await Request.addAdmin({ ...valData })
            setLoader(false)
            if (success) {
                notification.success({
                    message: message
                })
                form.resetFields()
            } else {
                notification.error({
                    message: message || "Failed"
                })
            }
        }
    }

    const requiredRule = [{ required: true, message: 'Please input field' }]
    const apiFunction = async () => {
        let params = getUrlParams('editUser', window.location.pathname)
        if (params?.id) {
            setParams(params)
            let { user, token, success, message, data } = await Request.getAdmin(params?.id)
            if (success) {
                let mainData = data
                // if (mainData?.department)
                //     mainData.department = _.find(deptData, val => val?.id == mainData?.department)?.id

                // if (mainData?.callercountry)
                //     mainData.callercountry = _.find(countries, val => val?.value == mainData?.callercountry)?.value
                mainData = {
                    ...mainData.auth,
                    ...mainData,
                }
                form.setFieldsValue({ ...mainData })
            } else {
                notification.error({
                    message: message || "Failed"
                })
            }

        }
    }

    useEffect(async () => {
        apiFunction()
        getFunction()
    }, [props])
    return (
        <Card style={{ flex: 1 }}>
            {/* {console.log(form.getFieldsValue())} */}
            <Form
                {...layout}
                form={form}
                onFinish={onFinish}
            >
                <FormItem
                    label={'First Name'}
                    name="firstname"
                    rules={requiredRule}
                />
                <FormItem
                    label={'Last Name'}
                    name="lastname"
                    rules={requiredRule}
                />
                <FormItem
                    label={'Phone'}
                    name="mobile"
                    maxLength={10}
                    rules={requiredRule}
                />
                <FormItem
                    label={'Role'}
                    name="role"
                    inputType={'SELECT'}
                    options={[{ label: 'Admin', value: 'admin' }, { label: 'Support', value: 'support' }]}
                    rules={requiredRule}
                />
                {!params && <FormItem
                    label={'Country'}
                    name="country"
                    inputType={"SELECT"}
                    rules={requiredRule}
                    defaultValue={'IN'}
                    options={_.map(countries, val => { return ({ label: val?.countryname, value: val?.countrycode }) })}
                />}
                <FormItem
                    label={'Email Id'}
                    name="email"
                    rules={[{ ...requiredRule[0], message: 'Input must be of email', type: 'email' }]}
                />
                {!params && <FormItem
                    label={'password'}
                    name="password"
                    inputType="PASSWORD"
                    rules={requiredRule}
                />}
                <div >
                    <FormItem
                        inputType="BUTTON"
                        tailLayout={tailLayout}
                        type="primary"
                        buttonStyle={{ width: 180, marginTop: 50 }}
                        loading={loader}
                        title="SUBMIT"
                        htmlType="submit" />
                </div>
            </Form>
        </Card >
    );
};

export default Demo