import { useState, lazy, useEffect } from "react";
import {
    Card,
    Space,
    Tooltip,
    notification,
    Radio,
    message,
    Table,
    Tabs,
    Button,
    Input,
    Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
    AudioOutlined,
    DeleteColumnOutlined,
    SearchOutlined,
    DeleteOutlined,
    DeleteTwoTone,
    EditOutlined,
} from "@ant-design/icons";
import { getUrlParams } from '../../routes';
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import async from "async";
import _ from "lodash";
import FilterTable from "../../components/Table";
import { sData } from "../../action";

import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import { Progress } from 'antd';



const SearchedUser = (props) => {
    const dispatch = useDispatch();
    const { currentUser, showPlan } = useSelector((state) => ({
        currentUser: state.global.currentUser,
    }));
    const [serverData, setServerData] = useState()
    const [serverDataRec, setServerDataRec] = useState()
    const [diskPercentage, setDiskPercentage] = useState()
    const [recPercentage, setRecPercentage] = useState()
    const [params, setParams] = useState(false)
    const [loader1, setLoader1] = useState(false)
    const [CPU, setCPU] = useState("")
    const [RAM, setRAM] = useState("")


    const options = {
        chart: {
            type: "pie",
            width: 360,
        },
        title: {
            text: `<div style= "font-weight:700">System Disk</div>`
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            pie: {

                size: 173,
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: 11.5,
                        fontWeight: '1000'
                    },
                    format: '{point.name}:<br>{point.y:.1f}<br />',
                },
                format: '{point.y:.1f}'
            }
        },

        series: [
            {
                colors: ['#e60000', '#003f5c', '#ff6361','#ffa600'],
                lineWidth: 1,

                marker: {
                    enabled: false,
                    symbol: "square",
                    radius: 4,
                    states: {
                        hover: {
                            enabled: true,
                            lineWidth: 1
                        }
                    }
                },
                data: serverData,
            }
        ]
    };
    const options1 = {
        chart: {
            type: "pie",
            width: 380,
        },
        title: {
            text: `<div style= "font-weight:700">Recording Disk</div>`
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            pie: {
                size: 173,
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: 11.5,
                        fontWeight: '1000'
                    },
                    format: '<b>{point.name}</b>:<br>{point.y:.1f} <br />',
                },
                // showInLegend: {
                //     display: false,
                //     position: "left"
                // },
                format: '{point.y:.1f}'
            }
        },
        series: [
            {
                
                colors: ['#ff4d4f', '#1890ff'],
                lineWidth: 1,
                marker: {
                    enabled: false,
                    symbol: "square",
                    radius: 4,
                    states: {
                        hover: {
                            enabled: true,
                            lineWidth: 1
                        }
                    }
                },
                data: serverDataRec,
            }
        ]
    };
    let FQDN = useSelector((state) => state.sData);
    
    const server = async (val) => {
        setLoader1(true)
        let resp = await Request.Asteriskdata({ "value": FQDN })
        setLoader1(false)
        console.log(resp, ">>>>>>>>>>><<<<<<<")

        if (resp?.success) {
            let pieData = Object.values(resp?.data?.disk_usage)
            setDiskPercentage(resp?.data?.disk_usage?.percent)

            let keydata = Object.keys(resp?.data?.disk_usage)
            let pKey = { "Used": pieData[1], "Free": pieData[2] }
            
            let newData = []
            async.forEachOf(pKey, (val, key, cb) => {
                let obj = {
                    name: key,
                    y: val,
                    sliced: false,
                    selected: true
                }
                newData.push(obj)
                cb()
            }, () => {
                setServerData(newData)
                console.log(serverData, "Hello")
            })

            let pieData2 = Object.values(resp?.data?.disk_usage_rec)
            setRecPercentage(resp?.data?.disk_usage_rec?.percentrec)
            
            let pKey2 = { "Used Rec": pieData2[1], "Free Rec": pieData2[2] }
            
            let newData2 = []
            async.forEachOf(pKey2, (val, key, cb) => {
                let obj = {
                    name: key,
                    y: val,
                    sliced: false,
                    selected: true
                }
                newData2.push(obj)
                cb()
            }, () => {
                setServerDataRec(newData2)
                console.log(serverDataRec, "Hello")
            })
            setCPU(resp?.data?.cpu_usage)
            setRAM(resp?.data?.ram_usage)
        }
        else {
            setServerData([])
            setServerDataRec([])
            setCPU("")
        }
    }
    
   
    useEffect(() => {
        server();
    }, []);



    return (
        <div style={{ display: "flex", flexWrap: "wrap",  justifyContent: "center" }}>

            <div style={{ paddingLeft: "4px", flex: "1", minWidth: '24rem' }}>
                <PieChart highcharts={Highcharts} options={options} />
                <div style={{ margin: "1em", fontSize: "16px", fontWeight: "700" }} ><h2>System</h2> <Progress percent={diskPercentage} /> </div>
            </div>

            <div style={{ paddingLeft: "10px", flex: "1", minWidth: '24rem' }}><PieChart highcharts={Highcharts} options={options1} />
                <div style={{ margin: "1em", fontSize: "16px", fontWeight: "700" }}><h2>Recording</h2> <Progress percent={recPercentage} /> </div>
            </div>

            <div style={{ paddingLeft: "10px", flex: "1", minWidth: '24rem' }}>
                <div style={{ margin: "1em", marginTop: "0.5em", fontSize: "16px", fontWeight: "700" }}><h2>CPU</h2> <Progress percent={CPU} /></div>
                <div style={{ margin: "1em", marginTop: "1em", fontSize: "16px", fontWeight: "700" }} ><h2>RAM</h2><Progress percent={RAM} /></div>
            </div>

        </div>
    );
};

export default SearchedUser;