import { useState, lazy, useEffect } from 'react';
import { Card, Space, Tooltip, notification, Radio, message, Table, Tabs, Button, Input, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined, DeleteColumnOutlined, SearchOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined } from '@ant-design/icons';
import { getPushPathWrapper } from '../../routes';
import Request from '../../request'
import moment from "moment";
import { confirmBox } from '../../utils';
import async from 'async'
import FilterTable from '../../components/Table'
import PendingCustomer from './PendingCustomer'
import ServerAllocation from './serverAllocation'
import SupportIndex from './supportIndex'
const { Search } = Input;


const HomeView = (props) => {
    const dispatch = useDispatch()
    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoader] = useState(false)


    return (
        <div>
            {currentUser?.auth?.role === "support" ? <SupportIndex /> : <>
                <PendingCustomer />
                <ServerAllocation /> </>}
        </div>
    )
}

export default HomeView;