import react, { useEffect, useState } from 'react'
import { Form, Input, Button, notification, Card, Switch } from 'antd';
import FormItem from '../../components/FormItem'
import './styles.less'
import { useDispatch } from 'react-redux';
import Request from '../../request';
import { getUrlParams } from '../../routes';
import _ from 'lodash'
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const Demo = (props) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)
    const [params, setParams] = useState('')
    const [data, setData] = useState({})
    const [checked, setChecked] = useState(false)

    const onFinish = async (valData, e) => {
        valData = {
            name: valData?.name,
            monthprice: valData?.monthprice,
            status: valData?.status,
            description: valData?.description,

        }
        if (params?.id) {
            setLoader(true)
            let { user, token, success, message, data } = await Request.updateAdOns(params?.id, { ...valData })
            // console.log(success, data, message)
            setLoader(false)
            if (success) {
                notification.success({
                    message: message
                })
            } else {
                notification.error({
                    message: message || "Failed",
                    description: JSON.stringify(data).replace('[', '').replace('{', '').replace(']', '').replace('}', '')
                })
            }
        }
        else {
            setLoader(true)
            let { user, token, status, message, success } = await Request.createAdOns(valData)
            setLoader(false)
            if (success) {
                notification.success({
                    description: <p> {message}</p>
                })
                form.resetFields()
                // setChecked(!checked)
            } else {
                notification.error({
                    message: `${message}` || "Failed",
                })
            }
        }
    }

    const requiredRule = [{ required: true, message: 'Please input field' }]
    const apiFunction = async () => {
        let params = getUrlParams('editAddOns', window.location.pathname)
        // console.log(params,'params')
        if (params?.id) {
            setParams(params)
            let { user, token, success, message, data } = await Request.getAdOns(params?.id)
            if (success) {
                form.setFieldsValue({ ...data })
            } else {
                notification.error({
                    message: message || "Failed"
                })
            }

        }
    }
    useEffect(() => {
        apiFunction()
    }, [props])


    return (
        <Card style={{ flex: 1 }}>
            <Form
                {...layout}
                form={form}
                onFinish={onFinish}
            >
                <FormItem
                    label={' Name'}
                    name="name"
                    rules={requiredRule}
                />


                <FormItem
                    label={'Month Price'}
                    name="monthprice"
                    inputType="NUMBER"
                    rules={requiredRule}

                />
                <FormItem
                    label={'Active'}
                    inputType={'SWITCH'}
                    name="status"
                />
                {/* <Form.Item label="Active" valuePropName="checked">
                    <Switch checked={checked} onChange={(e) => setChecked(!checked)} />
                </Form.Item> */}

                <FormItem
                    label={'Description'}
                    name="description"
                // rules={requiredRule}

                />

                <div className="marginTop">
                    <FormItem
                        inputType="BUTTON"
                        // tailLayout={tailLayout}
                        type="primary"
                        loading={loader}
                        buttonStyle={{ width: 'auto' }}
                        title="SUBMIT"
                        htmlType="submit" />
                </div>
            </Form>
        </Card >
    );
};

export default Demo