import react, { useEffect, useState } from "react";
import { Form, Upload, Button, notification, Card, Divider, Space } from "antd";
import FormItem from "../../components/FormItem";
import "./styles.less";
import { useDispatch } from "react-redux";
import Request from "../../request";
import _ from "lodash";
import { getUrlParams } from "../../routes";
import { UploadOutlined } from "@ant-design/icons";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 8,
  },
};

const Demo = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [params, setParams] = useState(false);
  const [countries, setCountries] = useState([]);
  const [formFileData, setData] = useState({});
  const [formLoader, setFormLoader] = useState(false);

  const onFinish = async (valData) => {
    if (params?.id) {
      setLoader(true);
      let { user, token, success, message, data } =
        await Request.updateOutgoing(params?.id, { ...valData });
      // console.log(success, data, message)
      setLoader(false);
      if (success) {
        notification.success({
          message: message,
        });
      } else {
        notification.error({
          message: message || "Failed",
        });
      }
    } else {
      setLoader(true);
      let { user, token, success, message, data } = await Request.addOutgoing(
        valData
      );
      setLoader(false);
      if (success) {
        notification.success({
          message: message,
        });
        form.resetFields();
      } else {
        notification.error({
          message: message || "Failed",
        });
      }
    }
  };
  const getFunction = async () => {
    let { user, token, success, data } = await Request.getCountries();
    if (success) {
      setCountries(() => data);
    } else {
      setCountries(() => []);
    }
  };
  const requiredRule = [{ required: true, message: "Please input field" }];
  const apiFunction = async () => {
    let params = getUrlParams("editoutgoing", window.location.pathname);
    if (params?.id) {
      setParams(params);
      let { user, token, success, message, data } = await Request.getOutgoing(
        params?.id
      );
      if (success) {
        let mainData = data;
        mainData.active = mainData?.active?.toString();
        mainData.tollfree = mainData?.tollfree?.toString();
        form.setFieldsValue({ ...mainData });
      } else {
        notification.error({
          message: message || "Failed",
        });
      }
    }
  };
  const onChangeFile = (info, key) => {
    const nextState = {};
    // console.log(info, key)
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFile = info.file;
        nextState.id = [info.file];
        setData((prev) => ({ ...prev, [key]: info.file }));
        break;
      case "done":
        nextState.selectedFile = info.file;
        nextState.id = [info.file];
        setData((prev) => ({ ...prev, [key]: info.file }));
        break;

      default:
        // error or removed
        nextState.selectedFile = null;
        nextState.id = [];
        setData((prev) => ({ ...prev, [key]: null }));
    }
  };
  const onFinish1 = async () => {
    if (!formFileData?.doc_1) {
      notification.error({
        message: "Please Upload file",
      });
      return;
    }
    const MAINFORM = new FormData();

    MAINFORM.append("file", formFileData?.doc_1?.originFileObj);
    // console.log(MAINFORM.getAll('file'))
    // return
    setFormLoader(true);
    let { user, success, message, data } = await Request.uploadOutgoing(
      MAINFORM
    );
    if (success) {
      notification.success({
        message: message || "success",
        // description: 'Successfully Registered Please Login To Continue'
      });
      setData(() => ({ doc_1: undefined }));
    } else {
      notification.error({
        message: message || "Failed",
        description: JSON.stringify(data)
          .replace("[", "")
          .replace("{", "")
          .replace("]", "")
          .replace("}", ""),
      });
    }
    setFormLoader(false);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  useEffect(async () => {
    apiFunction();
    getFunction();
  }, [props]);
  return (
    <Card style={{ flex: 1 }}>
      {!params && (
        <div className="flex jcc " style={{ justifyContent: "center", flexDirection: "column" }}>
          <div className="marginRight" style={{ fontSize: 16, marginBottom: "10px" }}>
            Bulk Upload Outgoing Charge :
          </div>
          <div className="marginRight" style={{ marginBottom: "10px" }}>
            {/* {console.log(formFileData, "formFileData")} */}
            <Upload
              name="doc_1"
              onChange={(p) => onChangeFile(p, "doc_1")}
              fileList={formFileData?.doc_1 ? [formFileData?.doc_1] : []}
              customRequest={dummyRequest}
              accept=".csv,.xls,.xlsx"
            >
              <Button icon={<UploadOutlined />}>Upload Csv/Excel</Button>
            </Upload>
          </div>
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            loading={formLoader}
            onClick={() => onFinish1()}
          >
            Submit File
          </Button>
        </div>
      )}
      {!params && <Divider />}
      <Form
        {...layout}
        // size="small"
        form={form}
        onFinish={onFinish}
      >
        <FormItem
          label={"Area Code"}
          name="areacode"
          maxLength={10}
          // inputType={'NUMBER'}
          width={200}
          rules={requiredRule}
        />
        <FormItem
          label={"Call Rate"}
          name="callrate"
          inputType={"NUMBERPRECISION"}
          width={200}
          rules={requiredRule}
        />
        <FormItem
          label={"Phone Type"}
          name="phonetype"
          // inputType={'NUMBER'}
          width={200}
        />
        <FormItem
          label={"Included"}
          name="included"
          inputType={"RADIO"}
          radioOptions={["true", "false"]}
          rules={requiredRule}
        />
        <FormItem
          label={"Country"}
          name="country"
          inputType={"SELECT"}
          rules={requiredRule}
          options={_.map(countries, (val) => {
            return { label: val?.countryname, value: val?.countrycode };
          })}
        />
        <div>
          <FormItem
            inputType="BUTTON"
            tailLayout={tailLayout}
            type="primary"
            buttonStyle={{ width: 180, marginTop: 10 }}
            loading={loader}
            title="SUBMIT"
            htmlType="submit"
          />
        </div>
      </Form>
    </Card>
  );
};

export default Demo;
