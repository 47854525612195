import { useState, lazy, useEffect } from "react";
import {
  Card,
  Switch,
  Tooltip,
  notification,
  Radio,
  message,
  Table,
  Tabs,
  Button,
  Input,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  OrderedListOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import { putData } from "../../action";
import { Route, Link } from "react-router-dom";
import { replace } from "lodash";
import FilterTable from "../../components/Table";

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoader] = useState(false);
  const [phoneNumberPermissions, setPhoneNumberPermissions] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [number, setNumber] = useState({});

  const deleteNumber = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } =
          await Request.deleteNumbers(fields?.id);
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "success",
          });
          apiFunction();
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };

  useEffect(() => {
    currentUser?.message.find((key, val) => {
      if (key.permissionType == "Phone Numbers") {
        setPhoneNumberPermissions(key);
      }
    });
  }, []);

  let SendData = (id) => {
    dispatch(putData(id));
  };

  const setShowModalFxn = (record) => {
    dispatch(getPushPathWrapper("editNumber", record));
    // request
  };
  const onChange = async (val, id) => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.updateNumber(
      id,
      { active: val }
    );
    setLoader(false);
    if (success) {
      notification.success({
        message: message || "success",
      });
      apiFunction();
    } else {
      notification.error({
        message: message || "Failed",
      });
    }
  };
  const columns = [
    {
      title: "Phone Number",
      dataIndex: "phonenumber",
      key: "phonenumber",
      search: true,
    },
    {
      title: "Phone Code",
      dataIndex: "phonecode",
      key: "phonecode",
      search: true,
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Province",
      dataIndex: "province",
      key: "province",
    },
    {
      title: "Customer Name",
      dataIndex: "customername",
      key: "customername",
      render: (value, record) => {
        return (
          <>
            <Link
              to="SearchedUser/"
              onClick={() => SendData(record.userid?.id)}
            >
              {record?.userid?.auth?.firstname
                ? record?.userid?.auth?.firstname
                : " "}{" "}
              {}{" "}
              {record?.userid?.auth?.lastname
                ? record?.userid?.auth?.lastname
                : " "}{" "}
            </Link>
          </>
        );
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (val, record) => {
        return (
          <Switch
            checked={val}
            onChange={(e) => {
              phoneNumberPermissions?.updateStatus && onChange(e, record?.id);
            }}
          />
        );
      },
    },

    // {
    //   title: 'Created At',
    //   dataIndex: 'createdon',
    //   key: 'createdon',
    //   render: (val, r) => {
    //     return <Tag>{moment(val).format('DD-MM-YYYY')}</Tag>
    //   }
    // },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 180,
      render: (val, record) => {
        return (
          <div className="flex">
            <div>
              {phoneNumberPermissions?.deleteStatus ? (
                <Tooltip title="Delete Number">
                  <DeleteOutlined
                    style={{ color: "red", marginRight: 10, fontSize: 20 }}
                    onClick={() => deleteNumber(record)}
                  />
                </Tooltip>
              ) : (
                <></>
              )}

              {phoneNumberPermissions?.updateStatus ? (
                <Tooltip title="Update Number">
                  <EditOutlined
                    style={{ color: "black", fontSize: 20 }}
                    onClick={() => setShowModalFxn(record)}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      },
    },
  ];
  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.getAllNumbers();
    setLoader(false);
    if (success) {
      setDataSource(() => data);
    } else {
      setDataSource(() => []);

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  };
  useEffect(() => {
    apiFunction();
  }, [phoneNumberPermissions?.listStatus]);
  return (
    <div>
      <div className="flex jcsb marginRightLarge">
        <div>
          {/* <Input
          placeholder="Search"
          suffix={
              <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          }
          style={{borderRadius:'4px'}}
        /> */}
        </div>
        {phoneNumberPermissions?.updateStatus ? (
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            onClick={() => dispatch(getPushPathWrapper("AllNumber.addNumber"))}
          >
            Add Number
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="marginTop">
        {phoneNumberPermissions?.listStatus && (
          <FilterTable
            bordered
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            scroll={{ x: 'auto' }}
          />
        )}
      </div>
    </div>
  );
};

export default HomeView;
