import { useState, lazy, useEffect } from "react";
import {
  Card,
  Space,
  Tooltip,
  Switch,
  Result,
  notification,
  Form,
  Modal,
  Radio,
  message,
  Table,
  Drawer,
  Tabs,
  Button,
  Input,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  AudioOutlined,
  DeleteColumnOutlined,
  SearchOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import FormItem from "../../components/FormItem";
import async from "async";
import FilterTable from "../../components/Table";

const { Search } = Input;

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [form] = Form.useForm();
  const [loading1, setLoader1] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [visible, setVisible] = useState(false);
  const [userRole, setUserRole] = useState();
  const [user1, setUser] = useState();
  const [userName, setUserName] = useState();
  const [employeePermissions, setEmployeePermissions] = useState();
  const [loading, setLoader] = useState(false);
  const [dataSource1, setDataSource1] = useState([]);

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  useEffect(() => {
    currentUser?.message.find((key, val) => {
      if (key.permissionType == "Employees") {
        setEmployeePermissions(key);
      }
    });
  }, []);

  // const onSearch = value => console.log(value);

  const onChange = async (val, id) => {
    console.log(val, id);
    setLoader(true);
    let { user, token, success, message, data } =
      await Request.updatePermission(id, { userId: user1?.id, ...val });
    setLoader(false);
    if (success) {
      notification.success({
        message: message || "success",
      });
      apiFunction1(user1);
      setUser();
    } else {
      notification.error({
        message: message || "Failed",
      });
    }
  };

  // const onFinish = async (valData) => {
  //     setLoader(true)
  //     valData = {
  //         userType: userRole,
  //         ...valData
  //     }
  //     let { user, token, success, message, data } = await Request.addPermission(valData)
  //     setLoader(false)
  //     if (success) {
  //         notification.success({
  //             message: message
  //         })
  //         form.resetFields()
  //         setIsModalVisible(false);
  //         apiFunction1(userRole)
  //     }
  //     else {
  //         notification.error({
  //             message: `${message}` || "Failed",
  //             description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
  //         })
  //     }

  // }

  const deletePermission = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } =
          await Request.deletePermission(fields?.id, {});
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "Failed",
          });
          apiFunction();
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };

  const deleteUser = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } = await Request.deleteAdmin(
          fields?.id,
          {}
        );
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "Failed",
          });
          apiFunction();
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };
  const edit = (record) => {
    // console.log(record)
    dispatch(getPushPathWrapper("editUser", record));
  };
  const columns = [
    {
      title: "First name",
      dataIndex: "firstname",
      key: "firstname",
      width: 150,
      // fixed: "left",
      search: true,
    },
    {
      title: "Last name",
      dataIndex: "lastname",
      key: "lastname",
      width: 150,
      // fixed: "left",
      search: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
      search: true,
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      search: true,
    },
    // {
    //     title: 'Country',
    //     dataIndex: 'callercountry',
    //     key: 'callercountry',
    // },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Working hours",
      dataIndex: "workinghours",
      key: "workinghours",
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
    },
    {
      title: "Active",
      dataIndex: "isactive",
      key: "isactive",
      render: (val, r) => {
        return <Tag color={val ? "#87d068" : "#f50"} style={{ borderRadius: 30, width: 60, justifyContent: "center", margin: 'auto' }}>{val ? "Yes" : "No"}</Tag>;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdon",
      key: "createdon",
      render: (val, r) => {
        return <Tag>{moment.utc(val).format("DD-MM-YYYY")}</Tag>;
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 100,
      fixed: "right",
      render: (val, record) => {
        return (
          <div className="flex" style={{ justifyContent: "space-around" }}>
            <div>
              <Tooltip title="Callers">
                <EyeOutlined
                  style={{ fontSize: 20 }}
                  onClick={() => {
                    setVisible(true);
                    setUserRole(record?.role);
                    apiFunction1(record);
                    setUser(record);
                    setUserName(record?.firstname + " " + record?.lastname);
                  }}
                />
              </Tooltip>
            </div>
            {employeePermissions?.updateStatus ? (
              <div>
                <EditOutlined
                  style={{ color: "primary", fontSize: 20 }}
                  onClick={() => edit(record)}
                />
              </div>
            ) : (
              <></>
            )}
            {employeePermissions?.deleteStatus ? (
              record?.role == "employee" ? (
                <></>
              ) : (
                <div>
                  <DeleteOutlined
                    style={{ color: "red", fontSize: 20 }}
                    onClick={() => deleteUser(record)}
                  />
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];

  const columns1 = [
    {
      title: "Permission Type",
      dataIndex: "permissionType",
      key: "permissionType",
      search: true,
    },
    {
      title: "List Status",
      dataIndex: "listStatus",
      key: "listStatus",
      render: (val, record) => {
        return (
          <Switch
            checked={val}
            onChange={(e) => onChange({ listStatus: e }, record?.id)}
          />
        );
      },
    },
    {
      title: "Create Status",
      dataIndex: "createStatus",
      key: "createStatus",
      render: (val, record) => {
        return (
          <Switch
            checked={val}
            onChange={(e) => onChange({ createStatus: e }, record?.id)}
          />
        );
      },
    },
    {
      title: "Update Status",
      dataIndex: "updateStatus",
      key: "updateStatus",
      render: (val, record) => {
        return (
          <Switch
            checked={val}
            onChange={(e) => onChange({ updateStatus: e }, record?.id)}
          />
        );
      },
    },
    {
      title: "Delete Status",
      dataIndex: "deleteStatus",
      key: "deleteStatus",
      render: (val, record) => {
        return (
          <Switch
            checked={val}
            onChange={(e) => onChange({ deleteStatus: e }, record?.id)}
          />
        );
      },
    },
    // {
    //     title: 'Action',
    //     dataIndex: 'Action',
    //     key: 'Action',
    //     fixed: 'right',
    //     render: (val, record) => {
    //         return <div className="flex" style={{ justifyContent: 'space-around' }}>
    //             <div>
    //                 <DeleteOutlined style={{ color: "red", fontSize: 20 }} onClick={() => deletePermission(record)} />
    //             </div>
    //         </div>
    //     }
    // },
  ];
  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.allAdmin();
    setLoader(false);
    let newData = [];
    if (success) {
      async.forEach(
        data,
        (val, cb) => {
          let obj = {
            ...val?.auth,
            ...val,
          };
          newData.push(obj);
          cb();
        },
        () => {
          setDataSource(newData);
        }
      );
    } else {
      // notification.error({
      //     message: message || "Failed"
      // })
    }
  };

  const apiFunction1 = async (record) => {
    setLoader1(true);
    let { user, token, success, message, data } =
      await Request.superUserPermissions({ userId: record?.id });
    setLoader1(false);
    let newData = [];
    if (success) {
      async.forEach(
        data,
        (val, cb) => {
          let obj = {
            ...val,
          };
          newData.push(obj);
          cb();
        },
        () => {
          setDataSource1(newData);
        }
      );
    } else {
      setDataSource1([]);
      // notification.error({
      //     message: message || "Failed"
      // })
    }
  };

  useEffect(() => {
    apiFunction();
  }, [employeePermissions?.listStatus]);
  return (
    <div>
      <div className="flex jcsb marginRight">
        {employeePermissions?.listStatus ? (
          <div>
            <Input
              placeholder="Search"
              suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
              style={{ borderRadius: "4px" }}
            />
          </div>
        ) : (
          <></>
        )}
        {employeePermissions?.createStatus ? (
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            onClick={() => dispatch(getPushPathWrapper("addUser"))}
          >
            Add Admin
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="marginTop">
        {employeePermissions?.listStatus ? (
          <FilterTable
            bordered
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            scroll={{ x: 1500 }}
          />
        ) : (
          <></>
        )}
      </div>
      {employeePermissions?.listStatus ? (
        <Modal
          title={`Permissions for ${userName}`}
          destroyOnClose={true}
          width={1200}
          height={600}
          footer={null}
          visible={visible}
          onOk={() => {
            setVisible(false);
            setUserRole();
            setUserName();
          }}
          onCancel={() => {
            setVisible(false);
            setUserRole();
            setUserName();
          }}
        >
          <FilterTable
            bordered
            dataSource={dataSource1}
            columns={columns1}
            loading={loading1}
            scroll={{ x: 'auto' }}
          />
        </Modal>
      ) : (
        <Result
          style={{ marginTop: "10vh" }}
          title="You don't have the permission to access this page"
        />
      )}
      {/* <Drawer
            title="Permissions"
            placement="right"
            closable={true}
            width={1200}
            onClose={() => { setVisible(false); setUserRole() }}
            visible={visible}
        >
            <Button type="primary" className="buttonPrimaryStyle" onClick={() => setIsModalVisible(true)}>
                Add Permissions for {userRole}
            </Button>
            <FilterTable bordered dataSource={dataSource1} columns={columns1} loading={loading1} scroll={{ x: 1500 }} />
        </Drawer> */}

      {/* <Modal title={(editId) ? "Edit DID" : "Add DID"} destroyOnClose={true} width={700} height={600} footer={null} visible={isModalVisible} onOk={() => { setIsModalVisible(false); setEditId() }} onCancel={() => { setIsModalVisible(false); setEditId() }}>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <FormItem
                    label={'Permission Type'}
                    name="permissionType"
                />

                <FormItem
                    label={'List Status'}
                    name="listStatus"
                    inputType={'SWITCH'}
                />
                <FormItem
                    label={'Create Status'}
                    name="createStatus"
                    inputType={'SWITCH'}
                />
                <FormItem
                    label={'Update Status'}
                    name="updateStatus"
                    inputType={'SWITCH'}
                />
                <FormItem
                    label={'Delete Status'}
                    name="deleteStatus"
                    inputType={'SWITCH'}
                />
                <div className="marginTop">
                    <FormItem
                        inputType="BUTTON"
                        type="primary"
                        buttonStyle={{ width: 200, marginTop: 20 }}
                        loading={loading}
                        title="SUBMIT"
                        htmlType="submit" />
                </div>
            </Form>
        </Modal> */}
    </div>
  );
};

export default HomeView;
