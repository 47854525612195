import react, { useEffect, useState } from "react";
import { Form, Switch, Input, Button, notification, Card, Select } from "antd";
import FormItem from "../../components/FormItem";
import "./styles.less";
import { useDispatch } from "react-redux";
import Request from "../../request";
import { getUrlParams } from "../../routes";
import _, { isArray } from "lodash";
import { push } from "connected-react-router";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Demo = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [params, setParams] = useState("");
  const [numbers, setNumber] = useState([]);
  const [listCodes, setListCodes] = useState([]);

  const onFinish = async (values) => {
    let valData = _.cloneDeep(values);

    valData.locations =
      valData?.locspecific && valData?.locations ? valData?.locations : null;
    // valData.customer = valData?.customer ? valData?.customer : null;

    if (valData.customerspecific && valData.customer) {
      valData.customer = isArray(valData.customer)
        ? valData.customer
        : Array.of(valData.customer);
      if (valData?.customer.length > 1) {
        valData.listOfCustomer = valData?.customer;
        valData.customer = null;
      } else {
        valData.customer = valData?.customer[0];
        valData.listOfCustomer = null;
      }
    } else {
      delete valData.customer;
      // valData.customer = null;
      delete valData.listOfCustomer;
      // valData.listOfCustomer = null;
    }

    if (params?.id) {
      setLoader(true);
      let { user, token, success, message, data } =
        await Request.updateCarriers(params?.id, { ...valData });
      // console.log(success, data, message)
      setLoader(false);
      if (success) {
        notification.success({
          message: message,
        });
      } else {
        notification.error({
          message: message || "Failed",
          description: JSON.stringify(data)
            .replace("[", "")
            .replace("{", "")
            .replace("]", "")
            .replace("}", ""),
        });
      }
    } else {
      setLoader(true);
      let { user, token, success, message, data } = await Request.addCarriers({
        ...valData,
      });
      setLoader(false);
      dispatch(push("/Carriers"));
      if (success) {
        notification.success({
          message: message,
        });
        form.resetFields();
      } else {
        notification.error({
          message: message || "Failed",
          description: JSON.stringify(data)
            .replace("[", "")
            .replace("{", "")
            .replace("]", "")
            .replace("}", ""),
        });
      }
    }
  };
  const NumberFxn = async () => {
    let { user, token, success, message, data } = await Request.allCustomer();
    if (success) {
      setNumber(() => data);
    } else {
      setNumber(() => []);
    }
  };
  const ListFxn = async () => {
    let { user, token, success, message, data } = await Request.allListcodes();
    if (success) {
      setListCodes(() => data);
    } else {
      setListCodes(() => []);
    }
  };
  const requiredRule = [{ required: true, message: "Please input field" }];
  const requiredRule1 = [
    {
      pattern: /^[a-zA-Z0-9]{0,30}$/,
      message: "Please write name without spaces and special characters",
    },
    { required: true, message: "Please input field" },
  ];
  const apiFunction = async () => {
    let params = getUrlParams("editCarrier", window.location.pathname);
    // console.log(params)
    if (params?.id) {
      setParams(params);
      let { user, token, success, message, data } = await Request.getCarriers(
        params?.id
      );
      if (success) {
        if (data.locspecific) {
          setLocShow(true);
        }
        if (data.customerspecific) {
          setCusShow(true);
        }
        form.setFieldsValue({
          ...data,
          locations: data?.locations ? data?.locations : [],
          customer: data?.customer ? data?.customer : data.listOfCustomer,
        });
      } else {
        notification.error({
          message: message || "Failed",
        });
      }
    }
  };
  useEffect(() => {
    apiFunction();
    NumberFxn();
    ListFxn();
  }, [props]);
  const [locShow, setLocShow] = useState(false);
  const [cusShow, setCusShow] = useState(false);

  const normFile = (e) => {
    // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <Card style={{ flex: 1 }}>
      <Form {...layout} form={form} onFinish={onFinish}>
        <FormItem label={"Name"} name="name" rules={requiredRule1} />
        <Form.Item
          label="User Name"
          name="username"
        >
          <Input
            placeholder="User Name"
            style={{ maxWidth: "75%", marginLeft: "4.5rem" }}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
        >
          <Input.Password placeholder="Password"
            style={{ maxWidth: "75%", marginLeft: "4.5rem" }} />
        </Form.Item>
        <FormItem
          label={"Prefix"}
          name="prefix"
        // rules={requiredRule}
        />
        <FormItem label={"IP"} name="ip" rules={requiredRule} />
        <FormItem
          label={"Customer Specific"}
          inputType={"SWITCH"}
          name="customerspecific"
          onChange={() => setCusShow(!cusShow)}
        />
        <FormItem
          label={"Loc Specific"}
          inputType={"SWITCH"}
          name="locspecific"
          onChange={() => setLocShow(!locShow)}
        />

        {cusShow && (
          <FormItem
            mode="multiple"
            label={"customer"}
            name="customer"
            initialValues=""
            // rules={requiredRule}
            inputType={"SELECT"}
            options={_.map(numbers, (val) => {
              return { label: val?.auth?.firstname, value: val?.id };
            })}
          />
        )}
        {locShow && (
          <FormItem
            mode="multiple"
            label={"Locations"}
            name="locations"
            inputType={"SELECT"}
            options={_.map(listCodes.codes, (val) => {
              return { label: val, value: parseInt(val) };
            })}
          />
        )}
        <div className="marginTop">
          <FormItem
            inputType="BUTTON"
            // tailLayout={tailLayout}
            type="primary"
            loading={loader}
            buttonStyle={{ width: "180px" }}
            title="SUBMIT"
            htmlType="submit"
          />
        </div>
      </Form>
    </Card>
  );
};

export default Demo;
