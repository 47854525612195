import { useState, lazy, useEffect } from "react";
import {
  Card,
  Space,
  Drawer,
  notification,
  Popover,
  message,
  Table,
  Tabs,
  Button,
  Input,
  Tag,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  AudioOutlined,
  CloseCircleOutlined,
  CheckSquareOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import async from "async";
import FilterTable from "../../components/Table";
import _ from "lodash";
import { ReactComponent as ADDRESS } from "../../assets/address.svg";
import { ReactComponent as ID } from "../../assets/id.svg";
import { useLocation } from 'react-router-dom';

const { Search } = Input;

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [document, setDocument] = useState([]);
  const [images, setImages] = useState("");
  const [loading, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  // const onSearch = value => console.log(value);

  const columns = [
    {
      title: "Customers",
      dataIndex: "customer",
      key: "customer",
      width: 150,
      // fixed: "left",
      render: (val, r) => {
        return (
          <>{r.customer?.auth?.firstname + " " + r.customer?.auth?.lastname}</>
        );
      },
    },
    {
      title: "Caller",
      dataIndex: "caller",
      key: "caller",
      width: 150,
      // fixed: "left",
      render: (val, r) => {
        return (
          <>{r.caller?.auth?.firstname + " " + r.caller?.auth?.lastname}</>
        );
      },
    },
    {
      title: "Endpoint",
      dataIndex: "endpoint",
      key: "endpoint",
      render: (val, r) => {
        return <>{r?.caller?.extdetail?.extensionregid}</>;
      },
    },
    {
      title: "Phone Code",
      dataIndex: "phonecode",
      key: "phonecode",
      search: true,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      search: true,
    },
    {
      title: "Third Party Number",
      dataIndex: "thirdpartynumber",
      key: "thirdpartynumber",
      search: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      search: true,
    },
    {
      title: "Server",
      dataIndex: "server",
      key: "server",
      render: (val, r) => {
        return <>{r?.caller?.extdetail?.serverip?.serverip}</>;
      },
    },
    {
      title: "Call Start Time",
      dataIndex: "createdon",
      key: "createdon",
      render: (val, r) => {
        return <>{moment.utc(val).format("hh:mm:ss a")}</>;
      },
    },
  ];

  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } =
      await Request.getSupportUserDashboard();
    setLoader(false);
    let newData = [];
    if (success) {
      async.forEach(
        data,
        (val, cb) => {
          let obj = {
            // ...val?.auth,
            ...val,
          };
          newData.push(obj);
          cb();
        },
        () => {
          setDataSource(newData);
        }
      );
    } else {
      // notification.error({
      //     message: message || "Failed"
      // })
    }
  };
  useEffect(() => {
    apiFunction();
  }, []);
  return (
    <div>
      <div className="marginTop">
        <div className="Customerapprovals">{location.pathname === "/Reports/LiveCalls" ? 'Real Time Calls Status' : 'Support Dashboard'}</div>
        <FilterTable
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          scroll={{ x: 'auto' }}
        />
      </div>
    </div>
  );
};

export default HomeView;
