import { useState, lazy, useEffect } from "react";
import {
  Card,
  Switch,
  Tooltip,
  Result,
  notification,
  Radio,
  message,
  Table,
  Tabs,
  Button,
  Input,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  OrderedListOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import { replace } from "lodash";
import FilterTable from "../../components/Table";

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoader] = useState(false);
  const [countryPermissions, setCountryPermissions] = useState("");

  const onChange = async (val, id) => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.updateCountries(
      id,
      { default_blocked: val }
    );
    setLoader(false);
    if (success) {
      notification.success({
        message: message || "success",
      });
      apiFunction();
    } else {
      notification.error({
        message: message || "Failed",
      });
    }
  };

  useEffect(() => {
    currentUser?.message.find((key, val) => {
      if (key.permissionType == "Countries") {
        setCountryPermissions(key);
      }
    });
  }, []);

  const columns = [
    {
      title: "Country Code",
      dataIndex: "countrycode",
      key: "countrycode",
    },
    {
      title: "Phone Code",
      dataIndex: "phonecode",
      key: "phonecode",
      // search: true
    },
    {
      title: "Country Name",
      dataIndex: "countryname",
      key: "countryname",
      search: true,
    },
    {
      title: "Blocked",
      dataIndex: "default_blocked",
      key: "default_blocked",
      render: (val, record) => {
        return (
          countryPermissions?.updateStatus && (
            <Switch checked={val} onChange={(e) => onChange(e, record?.id)} />
          )
        );
      },
    },
  ];
  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.getCountries();
    setLoader(false);
    if (success) {
      setDataSource(() => data);
    } else {
      setDataSource(() => []);

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  };
  useEffect(() => {
    apiFunction();
  }, [countryPermissions?.listStatus]);
  return (
    <div className="marginTop">
      {countryPermissions?.listStatus ? (
        <FilterTable
          bordered
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          scroll={{ x: 'auto' }}
        />
      ) : (
        <Result
          style={{ marginTop: "10vh" }}
          title="You don't have the permission to access this page"
        />
      )}
    </div>
  );
};

export default HomeView;
