import { useState, lazy, useEffect } from 'react';
import { Card, Space, Tooltip, notification, Radio, message, Table, Tabs, Button, Input, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './styles.less'
import { AudioOutlined, DeleteColumnOutlined, SearchOutlined, DeleteOutlined, DeleteTwoTone, EditOutlined } from '@ant-design/icons';
import { getPushPathWrapper } from '../../routes';
import Request from '../../request'
import moment from "moment";
import { confirmBox } from '../../utils';
import async from 'async'
import FilterTable from '../../components/Table'
import UserSearch from './UserSearch';

import { findData } from '../../action';





const Search = (props) => {
    const dispatch = useDispatch()
    const { currentUser } = useSelector((state) => ({
        currentUser: state.global.currentUser
    }))
    let searchTerm = useSelector((state)=> state.findData)

    // console.log(searchTerm, "searchtext");

    return <div>
      <UserSearch searchTerm={searchTerm}/> 
    </div>
}

export default Search;