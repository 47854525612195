import { useState, useEffect } from "react";
import { notification, Drawer, Input, Tag, Switch, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  AudioOutlined,
 
  DeleteOutlined,
  
  EyeOutlined,
} from "@ant-design/icons";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import async from "async";
import { Route, Link } from "react-router-dom";
import { putData,sData } from "../../action";
import FilterTable from "../../components/Table";

const { Search } = Input;

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [dataSource1, setDataSource1] = useState([]);

  const [loading, setLoader] = useState(false);
  const [loading1, setLoader1] = useState(false);

  const [customerPermissions, setCustomerPermissions] = useState("");

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  console.log(currentUser, "currentUser>>>>>>>>>>>");

  useEffect(() => {
    currentUser?.message.find((key, val) => {
      if (key.permissionType == "Customer") {
        setCustomerPermissions(key);
      }
    });
  }, []);

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );



  const deleteUser = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } =
          await Request.deleteCustomer(fields);
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "success",
          });
          apiFunction();
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };
  const edit = (record) => {
    dispatch(getPushPathWrapper("editUser", record));
  };

  let SendData = (id) => {
    dispatch(putData(id));
  };

  const onChange = async (val, id) => {
    console.log(val,id,"oCh")
    setLoader(true);
    let { user, token, success, message, data } = await Request.updateActive( id,{auth:{isactive:val}});
    setLoader(false);
    if (success) {
      notification.success({
        message: message || "success",
      });
      apiFunction();
    } else {
      notification.error({
        message: message || "Failed",
      });
    }
  };

  const columns = [
    {
      title: "First name",
      dataIndex: "firstname",
      key: "firstname",
      width: 150,
      
      render: (value, record) => {
        return (
          <>
            <Link to={`SearchedUser`} onClick={() => SendData(record.id)}>
              {" "}
              {value} {record?.lastname}{" "}
            </Link>
          </>
        );
      },
    },
    {
      title: "Business name",
      dataIndex: "businessname",
      key: "businessname",
      width: 250,
  
      search: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
     
      search: true,
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      width: 150,
      search: true,
    },

    {
      title: "Active",
      dataIndex: "isactive",
      key: "isactive",
      width: 90,
      render: (val, r) => {
        return (
          <Tag
            color={val ? "#87d068" : "#f50"}
            style={{
              borderRadius: 30,
              width: 60,
              justifyContent: "center",
              margin: "auto",
            }}
          >
            {val ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    {
      title: "Plan Type",
      dataIndex: "plantype",
      key: "plantype",
      width: 150,
    },
    {
      title: "Plan Name",
      dataIndex: "plan",
      key: "plan",
      width: 150,
      render: (value, record) => {
        return <>{record?.plan?.planname}</>;
      },
    },
    {
      title: "Update Active",
      dataIndex: "isactive",
      key: "isactive",
      render: (val, record) => {
     
        return (
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            checked={val}
            onChange={(e) => {
              onChange(e, record?.id);
            }}
          />
        );
      },
    },
    {
      title: "Total Numbers",
      dataIndex: "num_numbers",
      key: "num_numbers",
      width: 150,
    },
    {
      title: "Total Callers",
      dataIndex: "num_callers",
      key: "num_callers",
      width: 150,
    },
    {
      title: "Created At",
      dataIndex: "createdon",
      key: "createdon",
      width: 190,

      render: (val, r) => {
        return <Tag>{moment.utc(val).format("DD-MM-YYYY")}</Tag>;
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 180,
      fixed: "right",
      render: (val, record) => {
        return (
          <div className="flex">
            {customerPermissions?.deleteStatus ? (
              <div>
                <Tooltip title="delete">
                  <DeleteOutlined
                    style={{ fontSize: 20, color: "red" }}
                    onClick={() => {
                      deleteUser(record?.id);
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              <></>
            )}
            <div style={{ marginLeft: 10 }}>
              <Tooltip title="Callers">
                <EyeOutlined
                  style={{ fontSize: 20 }}
                  onClick={() => {
                    showDrawer();
                    apiFunction1(record?.id);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
  const columns1 = [
    {
      title: "First name",
      dataIndex: "firstname",
      key: "firstname",
      width: 120,
     
      search: true,
    },
    // {
    //     title: 'Last name',
    //     dataIndex: 'lastname',
    //     key: 'lastname',
    //     width: 150,
    //     fixed: 'left',
    //     search: true
    // },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
      search: true,
    },
    {
      title: "Allocated Phone",
      dataIndex: "allocatedphone",
      key: "allocatedphone",
      render: (val, r) => {
        return val?.phonenumber;
      },
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Status",
      dataIndex: "callerstatus",
      key: "callerstatus",
    },
    {
      title: "Working hours",
      dataIndex: "workinghours",
      key: "workinghours",
    },
    // {
    //     title: 'Comments',
    //     dataIndex: 'comments',
    //     key: 'comments',
    // },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (val, r) => {
        return (
          <Tag
            color={val ? "#87d068" : "#f50"}
            style={{
              borderRadius: 30,
              width: 60,
              justifyContent: "center",
              margin: "auto",
            }}
          >
            {val ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdon",
      key: "createdon",
      render: (val, r) => {
        return <Tag>{moment(val).format("DD-MM-YYYY")}</Tag>;
      },
    },
  ];
  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.allCustomer();
    setLoader(false);
    let newData = [];
    if (success) {
      async.forEach(
        data,
        (val, cb) => {
          let obj = {
            ...val?.auth,
            ...val,
          };
          newData.push(obj);
          cb();
        },
        () => {
          setDataSource(newData);
        }
      );
    } else {
      // notification.error({
      //     message: message || "Failed"
      // })
    }
  };
  const apiFunction1 = async (customerId) => {
    setLoader1(true);
    let { user, token, success, message, data } = await Request.allCallers({
      customer: customerId,
    });
    setLoader1(false);
    let newData = [];
    if (success) {
      async.forEach(
        data,
        (val, cb) => {
          let obj = {
            ...val?.auth,
            ...val,
          };
          newData.push(obj);
          cb();
        },
        () => {
          setDataSource1(newData);
        }
      );
    } else {
      setDataSource1([]);
      // notification.error({
      //     message: message || "Failed"
      // })
    }
  };
  useEffect(() => {
    apiFunction();
  }, [customerPermissions?.listStatus]);
  return (
    <div>
      <div className="marginTop">
        {customerPermissions?.listStatus && (
          <FilterTable
            bordered
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            scroll={{ x: "auto" }}
          />
        )}
      </div>
      <Drawer
        title="Callers"
        placement="right"
        closable={false}
        width={"70%"}
        onClose={onClose}
        visible={visible}
      >
        <FilterTable
          bordered
          dataSource={dataSource1}
          columns={columns1}
          loading={loading1}
          scroll={{ x: 1500 }}
        />
      </Drawer>
    </div>
  );
};

export default HomeView;
