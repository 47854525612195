import { useState, lazy, useEffect } from "react";
import {
  Card,
  Switch,
  Tooltip,
  notification,
  Radio,
  message,
  Table,
  Tabs,
  Button,
  Input,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  OrderedListOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import { replace } from "lodash";
import FilterTable from "../../components/Table";

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [callRatesPermissions, setCallRatesPermissions] = useState("");
  const [loading, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [number, setNumber] = useState({});

  const deleteIncoming = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } =
          await Request.deleteIncoming(fields?.id);
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "success",
          });
          apiFunction();
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };
  const setShowModalFxn = (record) => {
    dispatch(getPushPathWrapper("editincoming", record));
    // request
  };
  const onChange = async (val, id) => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.updateIncoming(
      id,
      { active: val }
    );
    setLoader(false);
    if (success) {
      notification.success({
        message: message || "success",
      });
      apiFunction();
    } else {
      notification.error({
        message: message || "Failed",
      });
    }
  };
  const columns = [
    {
      title: "Type",
      dataIndex: "tollfree",
      key: "tollfree",
      render: (val, r) => {
        return <>{val ? "TollFree" : "DID"}</>;
      },
      // search: true,
    },
    {
      title: "Call Rate",
      dataIndex: "callrate",
      key: "callrate",
      search: true,
    },
    {
      title: "Setup Cost",
      dataIndex: "setupcost",
      key: "setupcost",
    },
    {
      title: "Monthly Rental",
      dataIndex: "monthly_rental",
      key: "monthly_rental",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (val, record) => {
        return (
          <Switch
            checked={val}
            onChange={(e) => {
              callRatesPermissions?.updateStatus && onChange(e, record?.id);
            }}
          />
        );
      },
    },
    // {
    //   title: 'Toll-Free',
    //   dataIndex: 'tollfree',
    //   key: 'tollfree',
    //   render: (val, r) => {
    //     return <Tag color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Created At',
    //   dataIndex: 'createdon',
    //   key: 'createdon',
    //   render: (val, r) => {
    //     return <Tag>{moment(val).format('DD-MM-YYYY')}</Tag>
    //   }
    // },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 180,
      render: (val, record) => {
        return (
          <div className="flex">
            <div>
              {callRatesPermissions?.deleteStatus ? (
                <Tooltip title="Delete incoming ">
                  <DeleteOutlined
                    style={{ color: "red", marginRight: 10, fontSize: 20 }}
                    onClick={() => deleteIncoming(record)}
                  />
                </Tooltip>
              ) : (
                <></>
              )}

              {callRatesPermissions?.updateStatus ? (
                <Tooltip title="Update incoming ">
                  <EditOutlined
                    style={{ color: "black", fontSize: 20 }}
                    onClick={() => setShowModalFxn(record)}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      },
    },
  ];
  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } =
      await Request.getAllIncoming();
    setLoader(false);
    if (success) {
      setDataSource(() => data);
    } else {
      setDataSource(() => []);

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  };

  useEffect(() => {
    currentUser?.message.find((key, val) => {
      if (key.permissionType == "Call Rates") {
        setCallRatesPermissions(key);
      }
    });
  }, []);

  useEffect(() => {
    apiFunction();
  }, [callRatesPermissions?.listStatus]);
  return (
    <div>
      <div className="flex jcsb marginRightLarge">
        <div>
          {/* <Input
          placeholder="Search"
          suffix={
              <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          }
          style={{borderRadius:'4px'}}
        /> */}
        </div>
        {callRatesPermissions?.createStatus ? (
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            onClick={() => dispatch(getPushPathWrapper("Charge.addincoming"))}
          >
            Add Incoming Charge
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="marginTop">
        {callRatesPermissions?.listStatus && (
          <FilterTable
            bordered
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            scroll={{ x: 'auto' }}
          />
        )}
      </div>
    </div>
  );
};

export default HomeView;
