export const findData = (term) => {
  return {
    type: "FIND",
    payload: term,
  };
};

export const putData = (term) => {
  return {
    type: "PUT",
    payload: term,
  };
};

export const sData  = (term) => {
  return {
    type: "SDATA",
    payload: term,
  };
};
