import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import Store, { history } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import "./lightTheme.less";
import "./index.less";
import App from "./containers/app";
// import 'bootstrap/dist/css/bootstrap.min.css';

const { store, persistor } = Store();

store.subscribe(() => console.log(store.getState()));

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
