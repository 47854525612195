import React from "react";
import { useState, useEffect } from "react";
import { notification, Drawer, Input, Tag, Tooltip, Table } from "antd";
import async from "async";
import "./styles.less";
import Request from "../../request";
import FilterTable from "../../components/Table";
import { Route, Link } from "react-router-dom";
import SearchedUser from "./SearchedUser";
import moment from "moment";
import { putData } from "../../action";
import { useDispatch, useSelector } from "react-redux";

function UserSearch(props) {
  const [dataSource, setDataSource] = useState([]);

  const [visible1, setVisible1] = useState(false);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  let text = props?.searchTerm;

  const dispatch = useDispatch();

  let myId = useSelector((state) => state.putData);

  let SendData = (id) => {
    dispatch(putData(id));
  };

  const columns = [
    {
      title: "First name",
      dataIndex: "firstname",
      key: "firstname",
      width: 150,
      // fixed: 'left',
      render: (value, record) => {
        return (
          <>
            <Link to="SearchedUser/" onClick={() => SendData(record.id)}>
              {" "}
              {value} {record?.lastname}{" "}
            </Link>
          </>
        );
      },
    },
    {
      title: "Business name",
      dataIndex: "businessname",
      key: "businessname",
      width: 250,
      // fixed: 'left',
      search: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
      // fixed: 'left',
      search: true,
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      width: 150,
      search: true,
    },

    {
      title: "Active",
      dataIndex: "isactive",
      key: "isactive",
      width: 90,
      render: (val, r) => {
        return <Tag color={val ? "#87d068" : "#f50"}>{val ? "Yes" : "No"}</Tag>;
      },
    },
    {
      title: "Plan Type",
      dataIndex: "plantype",
      key: "plantype",
      width: 150,
    },
    {
      title: "Plan Name",
      dataIndex: "plan",
      key: "plan",
      width: 150,
      render: (value, record) => {
        return <>{record?.plan?.planname}</>;
      },
    },
  ];

  const apiFunction = async (text) => {
    if (props.searchTerm) {
      setLoading(true);

      let { user, token, success, message, data } = await Request.allCustomer({
        firstname: text,
      });
      setLoading(false);
      let newData = [];
      if (success) {
        async.forEach(
          data,
          (val, cb) => {
            let obj = {
              ...val?.auth,
              ...val,
            };
            newData.push(obj);
            cb();
          },
          () => {
            setDataSource(newData);

            console.warn(dataSource);
          }
        );
      } else {
        // notification.error({
        //     message: message || "Failed"
        // })
      }
    }
  };

  useEffect(() => {
    apiFunction();
  }, [text]);

  let filteredData;

  text ? (filteredData = dataSource) : (filteredData = null);

  if (text) {
    filteredData = dataSource.filter((value) => {
      const searchStr = text;
      const usernameMatches =
        value.firstname.match(new RegExp(searchStr, "gi")) ||
        value?.lastname?.match(new RegExp(searchStr, "gi"));

      const firstNameMatches = value.businessname.match(
        new RegExp(searchStr, "gi")
      );

      const emailMatches = value.email.match(new RegExp(searchStr, "gi"));

      const idMatches = value.mobile.match(new RegExp(searchStr, "gi"));
      // setVisible(false)
      // setLoading(true);
      return usernameMatches || firstNameMatches || emailMatches || idMatches; // || lastNameMatches;
    });
    // console.log(filteredData, "filterdata")
  }

  return (
    <>
      <Table
        bordered
        centered
        visible={visible1}
        dataSource={filteredData}
        columns={columns}
        pagination={false}
        scroll={{
          x: "auto",
          y: 330,
        }}
        loading={loading}
        style={{ marginTop: 10, padding: 10, whiteSpace: "pre" }}
      />
    </>
  );
}

export default UserSearch;
