import React from 'react'

import { push } from 'connected-react-router'
import _ from 'lodash'
import { Path } from 'path-parser'
import {
  AppstoreOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';

import Dashboard from './containers/dashboard'
import { ReactComponent as ProductIcon } from './assets/user.svg'
import { ReactComponent as HomeIcon } from './assets/homeIcon.svg'
import { ReactComponent as Group } from './assets/group.svg'
import { ReactComponent as Customer } from './assets/cust.svg'
// import {ReactComponent as Admin} from './assets/admin.svg'
import { ReactComponent as Document } from './assets/document.svg'

import { ReactComponent as CountriesSvg } from './assets/Countries.svg'
import { ReactComponent as NumberSvg } from './assets/Number.svg'
import { ReactComponent as ServersSvg } from './assets/servers.svg'
import { ReactComponent as CarrierSvg } from './assets/settings.svg'
import { ReactComponent as PlanSvg } from './assets/plan.svg'
import { ReactComponent as IncomingSvg } from './assets/incoming.svg'
import { ReactComponent as OutgoingSvg } from './assets/outgoing.svg'
import { ReactComponent as SettingsSvg } from './assets/settings.svg'

import AllCustomer from './containers/customers/list'
import FetchInvoice from './containers/customers/FetchInvoice'
import AllUsers from './containers/users/list'
import AddUser from './containers/users/add'



import Search from './containers/app/Search';
import UserSearch from './containers/app/UserSearch';
import SearchedUser from './containers/app/SearchedUser';

import AllDocumentType from './containers/documentType/list'
import AddDocumentType from './containers/documentType/add'

import AllCustomerStatus from './containers/customerStatus/list'
import AddCustomerStatus from './containers/customerStatus/add'

import AllAsteriskServer from './containers/asteriskServer/list'
import AddAsteriskServer from './containers/asteriskServer/add'
import Serverstatus from './containers/asteriskServer/Serverstatus'

import AllNumber from './containers/number/list'
import AddNumber from './containers/number/add'

import CustomDID from './containers/customDID/list'
import AddCustomDID from './containers/customDID/add'

import AllCarriers from './containers/carriers/list'
import AddCarrier from './containers/carriers/add'


import AllPlans from './containers/plans/list'
import AddPlan from './containers/plans/add'

import AddOns from './containers/addOns/addOns'
import AddAddOns from './containers/addOns/addAddOns'

import AllCountries from './containers/countries/list'

import AllIncoming from './containers/IncomingCall/list'
import AddIncoming from './containers/IncomingCall/add'


import AllOutgoing from './containers/outgoingCall/list'
import AddOutgoing from './containers/outgoingCall/add'
import LiveCall from './containers/dashboard/supportIndex'
import DepartmentListing from './containers/reports/departmentListing'
import CustomerCallerHistory from './containers/reports/customerCallerHistory'
import PhoneNumberSubHistory from './containers/reports/phoneNumberSubHistory'
import CustomDIDHistory from './containers/reports/customDIDHistory'

const menu = [
  {
    'path': '/dashboard',
    'name': 'Dashboard',
    'title': 'Dashboard',
    'icon': <HomeIcon />,
    'key': 'dashboard',
    'component': <Dashboard />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/AllCustomers',
    'name': 'Customers',
    'title': 'All Customer',
    'icon': <Customer />,
    'key': 'AllCustomer',
    'component': <AllCustomer />,
    'authority': [
      'user'
    ],
    children: [
      {
        'path': '/AllCustomer',
        'name': 'All Customers',
        'title': 'All Customer',
        'icon': <Customer />,
        'key': 'AllCustomer',
        'component': <AllCustomer />,
        'authority': [
          'user'
        ],
      },
      {
        'path': '/FetchInvoice',
        'name': 'Customers Invoice',
        'title': 'Customers Invoice',
        'icon': <Customer />,
        'key': 'FetchInvoice',
        'component': <FetchInvoice />,
        'authority': [
          'user'
        ],
      }
    ]
  },


  {
    'path': '/Numbers',
    'name': 'Numbers',
    'title': 'All Numbers',
    //     'icon': <NumberSvg />,
    'icon': <NumberSvg />,

    'key': 'AllNumber',
    // 'component': <AllNumber />,
    'authority': [
      'user'
    ],
    children: [
      {
        'path': '/Phonenumbers',
        'name': 'Phone Numbers',
        'title': 'Phone Numbers',
        //     'icon': <NumberSvg />,
        'icon': <NumberSvg />,

        'key': 'PhoneNumber',
        'component': <AllNumber />,
        'authority': [
          'user'
        ],
      },
      {
        'path': '/addNumber',
        'name': 'Add Number',
        'title': "Add Number",
        'icon': <Group />,
        "dontShowOnMenu": true,
        'key': 'addNumber',
        'component': <AddNumber />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/CustomDID',
        'name': 'Custom DID',
        'title': 'Custom DID',
        //     'icon': <NumberSvg />,
        'icon': <NumberSvg />,

        'key': 'CustomDID',
        'component': <CustomDID />,
        'authority': [
          'user'
        ],
      },
      {
        'path': '/addDID',
        'name': 'Add DID',
        'title': "Add DID",
        'icon': <Group />,
        "dontShowOnMenu": true,
        'key': 'addDID',
        'component': <AddCustomDID />,
        'authority': [
          'user'
        ]
      },
    ]
  },
  {
    'path': '/Phonenumbers/editNumber/:id',
    'name': 'Edit Number',
    'title': "Edit Number",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'editNumber',
    'component': <AddNumber />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/Phonenumbers/editDID/:id',
    'name': 'Edit DID',
    'title': "Edit DID",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'editDID',
    'component': <AddCustomDID />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/AllUsers',
    'name': 'Admins',
    'title': 'All Admins',
    'icon': <ProductIcon />,
    'key': 'AllUsers',
    'component': <AllUsers />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/addUser',
    'name': 'Add Admin',
    'title': "Add Admin",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'addUser',
    'component': <AddUser />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/editUser/:id',
    'name': 'Edit Admin',
    'title': "Edit Admin",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'editUser',
    'component': <AddUser />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/Charges',
    'name': 'Charges',
    'title': 'All Incoming Charge',
    //     'icon': <NumberSvg />,
    'icon': <OutgoingSvg />,

    'key': 'Charge',
    // 'component': <AllIncoming />,
    'authority': [
      'user'
    ],
    children: [

      {
        'path': '/IncomingCharge',
        'name': 'Incoming Charge',
        'title': 'All Incoming Charge',
        //     'icon': <NumberSvg />,
        'icon': <OutgoingSvg />,

        'key': 'IncomingCharge',
        'component': <AllIncoming />,
        'authority': [
          'user'
        ]
      },

      {
        'path': '/addIncomingCharge',
        'name': 'Add Incoming Charge',
        'title': "Add Incoming Charge",
        'icon': <Group />,
        "dontShowOnMenu": true,
        'key': 'addincoming',
        'component': <AddIncoming />,
        'authority': [
          'user'
        ]
      },



      {
        'path': '/OutgoingCharge',
        'name': 'Outgoing Charge',
        'title': 'All Outgoing Charge',
        //     'icon': <NumberSvg />,
        'icon': <OutgoingSvg />,

        'key': 'OutgoingCharge',
        'component': <AllOutgoing />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/addOutgoingCharge',
        'name': 'Add Outgoing Charge',
        'title': "Add Outgoing Charge",
        'icon': <Group />,
        "dontShowOnMenu": true,
        'key': 'addoutgoing',
        'component': <AddOutgoing />,
        'authority': [
          'user'
        ]
      },
    ]
  },
  {
    'path': '/editIncomingCharge/:id',
    'name': 'Edit Incoming Charge',
    'title': "Edit Incoming Charge",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'editincoming',
    'component': <AddIncoming />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/editOutgoingCharge/:id',
    'name': 'Edit Outgoing Charge',
    'title': "Edit Outgoing Charge",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'editoutgoing',
    'component': <AddOutgoing />,
    'authority': [
      'user'
    ]
  },


  {
    'path': '/Plans',
    'name': 'Plans',
    'title': 'All Plans',
    //     'icon': <NumberSvg />,
    'icon': <PlanSvg />,

    'key': 'Plans',
    // 'component': <AllPlans />,
    'authority': [
      'user'
    ],
    children: [

      {
        'path': '/Plans',
        'name': 'Plans',
        'title': 'All Plans',
        //     'icon': <NumberSvg />,
        'icon': <PlanSvg />,

        'key': 'AllPlans',
        'component': <AllPlans />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/Plans/addPlans',
        'name': 'Add Plan',
        'title': "Add Plan",
        'icon': <Group />,
        "dontShowOnMenu": true,
        'key': 'addPlan',
        'component': <AddPlan />,
        'authority': [
          'user'
        ]
      },

      {
        'path': '/AddOns',
        'name': 'AddOns',
        'title': 'AddOns Services',
        //     'icon': <NumberSvg />,
        'icon': <PlanSvg />,

        'key': 'AddOns',
        'component': <AddOns />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/AddOns/AddAddOns',
        'name': 'Add AddOns',
        'title': 'AddOns Services',
        //     'icon': <NumberSvg />,
        'icon': <PlanSvg />,
        "dontShowOnMenu": true,

        'key': 'addAddOns',
        'component': <AddAddOns />,
        'authority': [
          'user'
        ]
      },
    ]
  },
  {
    'path': '/Plans/editPlan/:id',
    'name': 'Edit Plan',
    'title': "Edit Plan",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'editPlan',
    'component': <AddPlan />,
    'authority': [
      'user'
    ]
  },

  {
    'path': '/Plans/editAddOns/:id',
    'name': 'Edit AddOns',
    'title': "Edit AddOns",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'editAddOns',
    'component': <AddAddOns />,
    'authority': [
      'user'
    ]
  },

  {
    'path': '/Server',
    'name': 'Servers',
    'title': 'Server',
    'icon': <ServersSvg />,
    // 'icon': <Group />,

    'key': 'Server',
    // 'component': <AllAsteriskServer />,
    'authority': [
      'user'
    ],

    children: [
      {
        'path': '/AsteriskServer',
        'name': 'Asterisk Server',
        'title': 'All Asterisk Server',
        'icon': <ServersSvg />,
        // 'icon': <Group />,

        'key': 'AllAsteriskServer',
        'component': <AllAsteriskServer />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/addAsteriskServer',
        'name': 'Add Asterisk Server',
        'title': "Add Asterisk Server",
        'icon': <Group />,
        "dontShowOnMenu": true,
        'key': 'addAsteriskServer',
        'component': <AddAsteriskServer />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/Carriers',
        'name': 'Carriers (VoIP)',
        'title': 'All Carriers',
        //     'icon': <NumberSvg />,
        'icon': <CarrierSvg />,

        'key': 'AllCarriers',
        'component': <AllCarriers />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/addCarriers',
        'name': 'Add Carrier',
        'title': "Add Carrier",
        'icon': <Group />,
        "dontShowOnMenu": true,
        'key': 'addCarrier',
        'component': <AddCarrier />,
        'authority': [
          'user'
        ]
      },

      // {
      //   'path': '/SMS',
      //   'name': 'SMS',
      //   'title': 'SMS',
      //   'icon': <ServersSvg />,
      //   // 'icon': <Group />,

      //   'key': 'AllAsteriskServer',
      //   // 'component': <AllAsteriskServer />,
      //   'authority': [
      //     'user'
      //   ]
      // },

    ]
  },
  {
    'path': '/editAsteriskServer/:id',
    'name': 'Edit Asterisk Server',
    'title': "Edit Asterisk Server",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'editAsteriskServer',
    'component': <AddAsteriskServer />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/editCarrier/:id',
    'name': 'Edit Carrier',
    'title': "Edit Carrier",
    'icon': <Group />,
    "dontShowOnMenu": true,
    'key': 'editCarrier',
    'component': <AddCarrier />,
    'authority': [
      'user'
    ]
  },



  {
    'path': '/Preferences',
    'name': 'Preferences',
    'title': 'All Preferences',
    'icon': <SettingsSvg />,
    'key': 'Preferences',
    // 'component': <AllCustomer />,
    'authority': [
      'user'
    ],
    children: [
      {
        'path': '/Preferences/AllDocumentType',
        'name': 'Document Type',
        'title': 'All Document Type',
        'icon': <Document />,
        'key': 'AllDocumentType',
        'component': <AllDocumentType />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/Preferences/addDocumentType',
        'name': 'Add Document Type',
        'title': "Add Document Type",
        'icon': <Group />,
        "dontShowOnMenu": true,
        'key': 'addDocumentType',
        'component': <AddDocumentType />,
        'authority': [
          'user'
        ]
      },

      {
        'path': '/Preferences/editDocumentType/:id',
        'name': 'Edit Document Type',
        'title': "Edit Document Type",
        'icon': <Group />,
        "dontShowOnMenu": true,
        'key': 'editDocumentType',
        'component': <AddDocumentType />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/Preferences/AllCustomerStatus',
        'name': 'Customer Status',
        'title': 'All Customer Status',
        'icon': <ProductIcon />,
        'key': 'AllCustomerStatus',
        'component': <AllCustomerStatus />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/Preferences/addCustomerStatus',
        'name': 'Add Customer Status',
        'title': "Add Customer Status",
        'icon': <Group />,
        "dontShowOnMenu": true,
        'key': 'addCustomerStatus',
        'component': <AddCustomerStatus />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/Preferences/customerStatus/:id',
        'name': 'Edit Customer Status',
        'title': "Edit Customer Status",
        'icon': <Group />,
        "dontShowOnMenu": true,
        'key': 'editCustomerStatus',
        'component': <AddCustomerStatus />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/AllCountries',
        'name': 'Countries',
        'title': 'Countries',
        // 'icon': <CountriesSvg />,/
        'icon': <CountriesSvg />,
        'key': 'AllCountries',
        'component': <AllCountries />,
        'authority': [
          'user'
        ]
      },
    ]
  },


  {
    'path': '/Search',
    'name': 'Search',
    'title': 'Search',
    "dontShowOnMenu": true,
    'icon': '',
    'key': 'search',
    'component': <Search />,
    'authority': [
      'user1'
    ]
  },
  {
    'path': '/UserSearch',
    'name': 'UserSearch',
    'title': 'UserSearch',
    "dontShowOnMenu": true,
    'icon': '',
    'key': 'userSearch',
    'component': <UserSearch />,
    'authority': [
      'user'
    ]
  },
  {
    'path': `/SearchedUser`,
    'name': 'Customer Detail',
    "dontShowOnMenu": true,
    'title': 'Customer Detail',
    'icon': '',
    'key': 'searchedUser',
    'component': <SearchedUser />,
    'authority': [
      'user'
    ]
  },
  {
    'path': `/Serverstatus`,
    'name': 'Server Status',
    "dontShowOnMenu": true,
    'title': 'Server Status',
    'icon': '',
    'key': 'serverstatus',
    'component': <Serverstatus />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/Reports',
    'name': 'Reports',
    'title': 'All Reports',
    'icon': <Document />,
    'key': 'Reports',
    // 'component': <AllCustomer />,
    'authority': [
      'user'
    ],
    children: [
      {
        'path': '/Reports/LiveCalls',
        'name': 'Live Calls',
        'title': 'All Live Calls',
        'icon': <PhoneOutlined />,
        'key': 'AllLiveCalls',
        'component': <LiveCall />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/Reports/DepartmentListing',
        'name': 'Customers Department History',
        'title': 'Customers Department History',
        'icon': <PhoneOutlined />,
        'key': 'CustomerDepartmentHistory',
        'component': <DepartmentListing />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/Reports/CustomerCallerHistory',
        'name': 'Customers Caller History',
        'title': 'Customers Caller History',
        'icon': <PhoneOutlined />,
        'key': 'CustomerCallerHistory',
        'component': <CustomerCallerHistory />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/Reports/PhoneNumberSubscriptionHistory',
        'name': 'Phone Number Subscription History',
        'title': 'Phone Number Subscription History',
        'icon': <PhoneOutlined />,
        'key': 'PhoneNumberSubscriptionHistory',
        'component': <PhoneNumberSubHistory />,
        'authority': [
          'user'
        ]
      },
      {
        'path': '/Reports/CustomDIDHistory',
        'name': 'Custom DID History',
        'title': 'Custom DID History',
        'icon': <PhoneOutlined />,
        'key': 'PhoneNumberSubscriptionHistory',
        'component': <CustomDIDHistory />,
        'authority': [
          'user'
        ]
      },
    ]
  },

]
// utils for path  

export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params = {}) => {

  let obj = getUrlObject(keyString)


  // console.log(obj,"obj")
  if (obj) {
    const path = new Path(obj.path)

    // console.log(path,"objpath")
    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {

  let obj = getUrlObject(keyString)
  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {

  let keyArr = keyString.split('.')

  // console.log(keyArr,'keyArr')

  let val = _.find(menu, p => p.key === keyArr[0])
  // console.log({val},'keyArr2')


  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
    // console.log(val,'keyArr3')
  }

  if (!val) {
    return `/`
  }
  //  console.log(val,'keyArr')
  return val
}
export default menu;