let initialState = [""];

const findTheData = (state = initialState, action) => {
  switch (action.type) {
    case "FIND":
      return (state = action.payload);

    default:
      return state;
  }
};
export default findTheData;
