import React, { useEffect, useState } from "react";
import { Layout, Menu, Button } from "antd";
import "./styles.less";
import { Link } from "react-router-dom";
import _ from "lodash";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import menu from "../../routes";
import { ReactComponent as HeadLogo } from "../../assets/dailmantraWhite.svg";
import {  useDispatch, useSelector } from "react-redux";

const { Sider } = Layout;
const { SubMenu } = Menu;

export default (props) => {
  const dispatch = useDispatch();
  const { menuData, path, collapsed, isMobile } = props;
  const [selected, setSelected] = useState("");
  const history = useHistory();

  const [openKeys, setOpenKeys] = useState([]);

  var [date, setDate] = useState(new Date());

  useEffect(() => {
    var timer = setInterval(() =>
   
     setDate(new Date()), 1000)
    return function cleanup() {
      clearInterval(timer)
     
    }

  });
  useEffect(() => {
    if (menuData) {
      _.forEach(menuData, (menu) => {
        if (path === menu.path) setSelected(() => menu.key);
      });
    }
  }, [menuData]);

  // const onClickMenu = (item) => {
  //     const clicked = menu.find(_item => _item.key === item.key)
  //     history.push(clicked.path)
  //   }
  const IsMainMenu = (key) => {
    return menuData.some((item) => {
      if (key) {
        return item.key === key || item.path === key;
      }
      return false;
    });
  };

  const handleOpenChange = (openKeys) => {
    // console.log(openKeys,"???????????")
    // const moreThanOne =
    //     openKeys.filter(openKey => IsMainMenu(openKey)).length > 1
    // console.log()
    setOpenKeys(() => openKeys);
  };

  const createSubMenu = (item) => {
    if (item.children) {
      return (
        <SubMenu
          key={item.key}
          title={
            <span>
              {item.icon}
              <span>{item.name}</span>
            </span>
          }
          className={"SubmenuItem"}
          style={{ width: 256, padding: 0 }}
        >
          {_.filter(item.children, (e) => !e.dontShowOnMenu).map((child) => {
            return (
              <Menu.Item
                key={child.key}
                title={child.name}
                className={"menuItemtwo"}
                onClick={(i) => setSelected(() => i.key)}
              >
                <Link to={child.path}>
                  {child.icon}
                  <span className="manuItemName2">{child.name}</span>
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
  };

  {
    let index = 23;

    menuData.splice(index, 1);
  }

  // const myConsoles = () => {
  //     console.log(Sider);
  // }
  // myConsoles();
  const toggleHeader = () => {
    dispatch({
      type: "SET_HEADER",
      payload: false,
    });
  };

  return (
    <> 
    <Sider
      className={"drawer"}
      trigger={null}
      collapsedWidth={0}
      style={{
        overflowY: "auto",
        height: "100vh",
      }}
      collapsible
      collapsed={collapsed}
    >
      <div>
        <div className={"namespace flex"}>
          <div
            className={" flex"}
            style={{ width: "3rem", marginRight: "9px" }}
          >
            <HeadLogo />
          </div>
          <div>
            <span
              className={"displayFlexcenter"}
              style={{ fontFamily: "Aldrich" }}
            >
              Dialmantra
            </span>
            <div className={"displayFlexcenter"} style={{ fontSize: 16 }}>
              ( Admin Portal )
            </div>
          </div>
          
        </div>
        
        <Menu
          mode="inline"
          selectedKeys={[selected]}
          onSelect={(item) => {
            // handleOpenChange(item)
            setSelected(() => item.key);
          }}
          // onOpenChange={(item) => handleOpenChange(item)}
          // openKeys={openKeys}
          onClick={() => toggleHeader()}
          className={"menu"}
        >
          {_.filter(menuData, (e) => !e.dontShowOnMenu).map((item) => {
            return item.children ? (
              createSubMenu(item)
            ) : (
              <Menu.Item
                key={item.key}
                title={item.name}
                className={"SubmenuItem"}
                onClick={(i) => {
                  toggleHeader();
                  console.log(i);
                }}
                
              >
                <Link
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  to={item.path}
                >
                  {item.icon}
                  <span className="SubmenuItem">{item.name}</span>
                </Link>
              </Menu.Item>
              
            );
            
          })}
           
         
        </Menu>
       
      </div>
      <div className="datetime"
      // style={{display:"flex",display:"",margin:"1em 0 0 1em",fontWeight:"700"}}
      >
            {date.toUTCString()}
           
          </div>
    </Sider>
   
</>
  );
};
