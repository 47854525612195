import { useState, useEffect } from "react";
import { DatePicker, Table, Button, Tag, Modal, Select, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  AudioOutlined,
  DeleteColumnOutlined,
  SearchOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox, countries } from "../../utils";
import async from "async";
import _ from "lodash";
import FilterTable from "../../components/Table";
import { putData } from "../../action";

const { RangePicker } = DatePicker;
const { Option } = Select;

const SearchedUser = (props) => {
  const dispatch = useDispatch();
  const { currentUser, allowedCountriesIds } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    allowedCountriesIds: state.global.allowedCountriesIds,
  }));
  let [dataSource, setDataSource] = useState([]);
  const [dataSource1, setDataSource1] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [caller, setCaller] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true);
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [plan, setPlan] = useState("");
  const [name, setName] = useState([]);
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(false);
  const [pageTotal, setPageTotal] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [countryListing, setCountryListing] = useState([]);
  const [form] = Form.useForm();

  let Id = useSelector((state) => state.putData);

  const apiFunction1 = async (Id) => {
    setLoading(true);
    let { user, token, success, message, data } = await Request.allCallers({
      customer: Id,
    });
    setLoading(false);
    let newData = [];
    if (success) {
      async.forEach(
        data,
        (val, cb) => {
          let obj = {
            ...val?.auth,
            ...val.extdetail,
            ...val.server,
            ...val.department,
            ...val.allocatedphone,
          };
          newData.push(obj);
          cb();
        },
        () => {
          setDataSource1(newData);
        }
      );
    } else {
      // notification.error({
      //     message: message || "Failed"
      // })
    }
  };

  const callAllowedCountriesListing = async () => {
    let { user, token, success, data } = await Request.customerDetail(Id);
    if (success) {
      const CIds = data.allowed_countries.map((country) => {
        return country.id;
      });
      dispatch({ type: "ALLOWED_COUNTRIES_LIST", payload: CIds });
    }
  };

  const apiFunction2 = async (Id) => {
    setLoading(true);
    let { user, token, success, message, data } = await Request.callHistory({
      customer: Id,
    });
    setLoading(false);
    if (success && data.length) {
      setDataSource(data);
    } else {
    }
  };

  const apiFunction3 = async (Id) => {
    let { user, token, success, data } = await Request.customerDetail(Id);
    if (success) {
      setData(data);
      setPlan(data.plan);
      setValue(data.balance);
      setName(data.auth);
    }
  };

  const getFunction = async (Id) => {
    setLoading(true);
    let { user, token, success, data } = await Request.graph({ customer: Id });
    console.log(data, "DATA");
    setLoading(false);
    if (success) {
      setGraphData(() => data);
    } else {
    }
  };

  const apiFunction = async (filter) => {
    filter.customer = Id;
    setLoader(true);
    let { user, token, success, message, data, total } =
      await Request.filterCallHistory(filter);
    setLoader(false);
    if (success && data.length) {
      setDataSource(data);
      setPageTotal(total);
    } else {
      setDataSource([]);

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  };
  useEffect(() => {
    setFilters({ date: [moment().subtract("days", 7), moment()] });
    apiFunction({ date: [moment().subtract("days", 7), moment()] });
  }, []);

  useEffect(() => {
    apiFunction3(Id);
    apiFunction1(Id);
    getFunction(Id);
    apiFunction2(Id);
    getCountriesListingFunc();
    callAllowedCountriesListing();
  }, []);

  const downloadCallHis = async (filter) => {
    setLoader(true);
    const data = await Request.downloadCallHistory(filter);
    setLoader(false);
  };

  const columns1 = [
    {
      title: "Caller Name",
      dataIndex: "firstname",
      key: "firstname",
      width: 250,
      render: (val, r) => {
        return <>{val + " " + r?.lastname}</>;
      },
    },
    {
      title: "Department",
      dataIndex: "deptname",
      key: "deptname",
      render: (val, r) => {
        return <>{r?.deptname}</>;
      },
      width: 250,
    },
    // {
    //     title: 'Last name',
    //     dataIndex: 'lastname',
    //     key: 'lastname',
    //     width: 150,
    //     fixed: 'left',
    //     search: true
    // },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      search: true,
      width: 250,
    },
    {
      title: "Allocated Phone",
      dataIndex: "allocatedphone",
      key: "allocatedphone",
      render: (val, r) => {
        // console.log(r, "r>>>>>>>>>>>>>>>>>>");
        return r?.phonenumber;
      },
      width: 250,
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      width: 250,
    },
    {
      title: "Active",
      dataIndex: "isactive",
      key: "isactive",
      render: (val, r) => {
        return (
          <Tag
            color={val ? "#87d068" : "#f50"}
            style={{
              borderRadius: 30,
              width: 60,
              justifyContent: "center",
              margin: "auto",
            }}
          >
            {val ? "Yes" : "No"}
          </Tag>
        );
      },
      width: 250,
    },

    // {
    //     title: 'Comments',
    //     dataIndex: 'comments',
    //     key: 'comments',
    // },
    //  {
    //      title: 'Active',
    //      dataIndex: 'active',
    //      key: 'active',
    //      render: (val, r) => {
    //          return <Tag color={val ? '#87d068' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //      }
    //  },

    //    {
    //     title: 'Extension',
    //     dataIndex: 'extension',
    //     key: 'extension',
    // },

    {
      title: "Server Ip",
      dataIndex: "serverip",
      key: "serverip",
      render: (val, r) => {
        return r.serverip?.serverip;
      },
      width: 250,
    },

    {
      title: "End-Point",
      dataIndex: "extensionregid",
      key: "extensionregid",
      render: (val, r) => {
        return r?.extensionregid;
      },
      width: 250,
    },

    // {
    //   title: 'Call Duration',
    //   dataIndex: 'callduration',
    //   key: 'callduration',
    // },
  ];

  const columns = [
    {
      title: "Caller Name",
      dataIndex: "firstname",
      key: "firstname",
      search: true,
      render: (val, r) => {
        return <>{r?.caller?.auth?.firstname ? r?.caller?.auth?.firstname : ""} {r?.caller?.auth?.lastname ? r?.caller?.auth?.lastname : ""}</>
      },
      width: 250,
    },
    {
      title: "Department",
      dataIndex: "deptname",
      key: "deptname",
      width: 250,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 250,
    },

    {
      title: "Call Type",
      dataIndex: "calltype",
      key: "calltype",
      width: 250,
      //  render: (val, r) => {
      //    return <Tag color={val == 'Outbound' ? 'blue' : "green"}>{val}</Tag>
      //  }
    },
    {
      title: "Date",
      dataIndex: "createdon",
      key: "createdon",
      render: (val, r) => {
        return <>{moment(val.slice(0, -1)).format("DD-MM-YYYY")}</>;
      },
      width: 250,
    },
    {
      title: "Time",
      dataIndex: "createdon",
      key: "createdon",
      render: (val, r) => {
        return <>{moment.utc(val).format("hh:mm:ss a")}</>;
      },
      width: 250,
    },
    {
      title: "Call Duration",
      dataIndex: "callduration",
      key: "callduration",
      width: 250,
    },
  ];

  // allowed countries modal functions ::

  const showModalFunc = () => {
    setModalOpen(true);
  };

  const handleCancelFunc = () => {
    setModalOpen(false);
  };

  // set countries listing ::

  async function getCountriesListingFunc() {
    const { data, message, error, success } = await Request.getCountries();
    if (success) {
      setCountryListing(data);
    } else {
      setCountryListing([]);
    }
  }

  // form submition::

  const onFinish = async (values) => {
    setLoader(true);
    const { data, error, success, message } = await Request.updateActive(
      Id,
      values
    );
    setLoader(false);
    if (success) {
      apiFunction3(Id);
      callAllowedCountriesListing();
    }
    setModalOpen(false);
  };

  const handleSearch = (value) => {
    console.log(value);
  };

  return (
    <div>
      <ul className="CustomerDetails">
        <li>
          <b>Name : </b>
          {name.firstname} {name.lastname}
        </li>
        <li>
          <b>Email : </b>
          {name.email}
        </li>
        <li>
          <b>Company : </b>
          {data ? data.businessname : "No Data"}
        </li>
        <li>
          <b>Mobile : </b>
          {name ? name.mobile : "No Data"}
        </li>
        <li>
          <b>Balance : </b>$ {value ? value.balance : "balance"}
        </li>
        <li>
          <b>Total Call This Month : </b>
          {graphData ? graphData.total : " No Data"}
        </li>
        <li>
          <b>Plan Name : </b>
          {plan ? plan.planname : "plan not selected"}
        </li>
        <li>
          <b>Plan Type : </b>
          {value
            ? value.plantype == "month"
              ? "Monthly"
              : "Yearly"
            : "plan not selected"}{" "}
        </li>
        <li>
          <b>Subscription : </b>
          {value ? value.selectedcallercount : "plan not selected"}{" "}
        </li>
        <li>
          <b>Subscribed Addons : </b>
          {_.map(data?.addons, (val) => {
            {
              console.log(
                val?.extra_detail?.addonsname,
                "?????????????????????????"
              );
            }
            // { console.log((val?.description).replace(new RegExp(/'/, 'g'), "\\'"), "?????????????????????????") }
            // str = val?.description.replace(/'/g, "\\'");
            return <> {val?.extra_detail?.addonsname}</>;
          })}
        </li>
      </ul>
      <div className="allowedContries" style={{ marginLeft: "15px" }}>
        <b>Allowed Countries : </b>
        {!data?.allowed_countries?.length ? (
          <div
            style={{ display: "inline-flex", gap: "1em", alignItems: "center" }}
          >
            <div>NA</div>
            <Button
              type="primary"
              loading={loading}
              style={{
                borderRadius: 3,
                fontSize: 9,
                minHeight: 5,
                paddingInline: 4,
              }}
              onClick={showModalFunc}
            >
              Add Countries
            </Button>
          </div>
        ) : (
          <>
            <div
              style={{
                display: "inline-flex",
                gap: "1em",
                alignItems: "center",
              }}
            >
              {data?.allowed_countries?.map((value, index) => {
                return <div key={index}>{value.countryname},</div>;
              })}
              <Button
                type="primary"
                loading={loading}
                style={{
                  borderRadius: 3,
                  fontSize: 9,
                  minHeight: 5,
                  paddingInline: 4,
                }}
                onClick={showModalFunc}
              >
                Edit Countries
              </Button>
            </div>
          </>
        )}
      </div>

      <h3
        style={{
          paddingTop: 20,
          paddingLeft: 20,
          marginTop: 10,
          width: "auto",
          display: "flex",
          justifyContent: "center",
          fontWeight: 1000,
        }}
      >
        Caller's Associated with the Customer Account
      </h3>
      <Table
        bordered
        centered
        visible={visible}
        dataSource={dataSource1}
        columns={columns1}
        pagination={false}
        loading={loading}
        scroll={{
          x: "auto",
        }}
        style={{ marginTop: 10, padding: 10, whiteSpace: "pre" }}
        className="callHistoryTable"
      />

      <div className="marginTopLarge">
        <h3
          style={{
            paddingTop: 20,
            paddingLeft: 20,
            marginTop: 10,
            width: "auto",
            display: "flex",
            justifyContent: "center",
            fontWeight: 1000,
          }}
        >
          Call History
        </h3>
        <div className="flex marginRight jcsb marginTop">
          <div className="flex marginRight ">
            <div className="marginRight">
              <RangePicker
                onChange={(v) => setFilters((prev) => ({ ...prev, date: v }))}
                value={filters?.date}
              />
            </div>
            <Button
              type="primary"
              className="buttonPrimaryStyle"
              loading={loading}
              onClick={() => {
                apiFunction(filters);
                setCurrentPage(1);
              }}
            >
              Search
            </Button>
          </div>
          <div className="downloadBtn">
            <Button
              type="primary"
              className="buttonPrimaryStyle"
              loading={loading}
              onClick={() => {
                downloadCallHis({ date: filters.date, customer: Id });
              }}
            >
              Download
            </Button>
          </div>
        </div>
      </div>

      <Table
        bordered
        centered
        visible={visible}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: currentPage,
          onChange: (page) => {
            apiFunction({ page: page, date: filters.date });
            setCurrentPage(page);
          },
          pageSize: 20,
          total: pageTotal,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        scroll={{
          x: "auto",
        }}
        loading={loading}
        style={{ marginTop: 10, padding: 10, whiteSpace: "pre" }}
        className="callHistoryTable"
      />

      {/* Modal For Allow Countries */}
      <Modal
        title="Edit Allowed Countries"
        visible={modalOpen}
        onCancel={handleCancelFunc}
        footer={false}
      >
        <Form
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          initialValues={{
            allowed_countries: allowedCountriesIds,
          }}
        >
          <Form.Item name="allowed_countries" label="Countries">
            <Select
              placeholder="Select Countries"
              mode="multiple"
              options={countryListing.map((country) => ({
                label: country.countryname,
                value: country.id,
              }))}
              showSearch
              optionFilterProp="children"
              filterOption={(input, country) => {
                return (
                  country.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SearchedUser;
