import { useState, lazy, useEffect } from "react";
import {
  Card,
  Space,
  Tooltip,
  notification,
  Radio,
  message,
  Table,
  Tabs,
  Button,
  Input,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  AudioOutlined,
  DeleteColumnOutlined,
  SearchOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import async from "async";
import FilterTable from "../../components/Table";
import { Link } from "react-router-dom";
import { sData } from "../../action";
const { Search } = Input;

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoader] = useState(false);
  const [serverPermissions, setServerPermissions] = useState("");

  const [serverData, setServerData] = useState()
  const [loader1, setLoader1] = useState(false)

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  useEffect(() => {
    currentUser?.message.find((key, val) => {
      if (key.permissionType == "Servers") {
        setServerPermissions(key);
      }
    });
  }, []);

  // const onSearch = value => console.log(value);

  const deleteFxn = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } =
          await Request.deletAsteriskServer(fields?.id, {});
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "Failed",
          });
          apiFunction();
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };
  const edit = (record) => {
    // console.log(record.id,"record")
    dispatch(getPushPathWrapper("editAsteriskServer", record));
  };

const sendValue=(fqdn)=>{
  console.log(fqdn,"FQDN")
  dispatch(sData(fqdn));
//   dispatch(getPushPathWrapper("Serverstatus", record));
}
  


  const columns = [
    {
      title: "Serverip",
      dataIndex: "serverip",
      key: "serverip",
      width: 200,
      render: (val, record) => {
        return (
          <>
            <Link to={`Serverstatus`} 
             onClick={()=>sendValue(record?.fqdn)}
             >
              {" "}
              {record?.serverip}{" "}
            </Link>
          </>
        );
      },
    },
    {
      title: "Total Extension",
      dataIndex: "totalextension",
      key: "totalextension",
      search: true,
    },
    {
      title: "Max Extension",
      dataIndex: "maxextension",
      key: "maxextension",
      search: true,
    },
    {
      title: "FQDN",
      dataIndex: "fqdn",
      key: "fqdn",
      search: true,
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (val, r) => {
        return <Tag color={val ? "#87d068" : "#f50"} style={{ borderRadius: 30, width: 60, justifyContent: "center", margin: 'auto' }}>{val ? "Yes" : "No"}</Tag>;
      },
    },
    {
      title: "Shareable",
      dataIndex: "shareable",
      key: "shareable",
      render: (val, r) => {
        return <Tag color={val ? "#87d068" : "#f50"} style={{ borderRadius: 30, width: 60, justifyContent: "center", margin: 'auto' }}>{val ? "Yes" : "No"}</Tag>;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdon",
      key: "createdon",
      render: (val, r) => {
        return <Tag>{moment(val).format("DD-MM-YYYY")}</Tag>;
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 180,
      fixed: "right",
      render: (val, record) => {
        return (
          <div className="flex">
            {serverPermissions?.deleteStatus ? (
              <div>
                <DeleteOutlined
                  style={{ color: "red", fontSize: 20 }}
                  onClick={() => deleteFxn(record)}
                />
              </div>
            ) : (
              <></>
            )}
            {serverPermissions?.updateStatus ? (
              <div style={{ marginLeft: 10 }}>
                <EditOutlined
                  style={{ color: "primary", fontSize: 20 }}
                  onClick={() => edit(record)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];
  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } =
      await Request.allAsteriskServer();
    setLoader(false);
    if (success) {
      setDataSource(data);
    } else {
      // notification.error({
      //     message: message || "Failed"
      // })
    }
  };
  useEffect(() => {
    apiFunction();
  }, [serverPermissions?.listStatus]);
  return (
    <div>
      <div className="flex jcsb marginRight">
        <div>
          {/* <Input
                    placeholder="Search"
                    suffix={
                        <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    }
                    style={{ borderRadius: '4px' }}
                /> */}
        </div>
        {serverPermissions?.createStatus ? (
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            onClick={() =>
              dispatch(getPushPathWrapper("Server.addAsteriskServer"))
            }
          >
            Add Server
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="marginTop">
        {serverPermissions?.listStatus && (
          <FilterTable
            bordered
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            scroll={{ x: 'auto' }}
          />
        )}
      </div>
    </div>
  );
};

export default HomeView;
