import React, { useState } from 'react'
import { Button, Typography, Input, Space, notification, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import Request from '../../request';
import { push } from 'connected-react-router';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Option } = Select
const { Text } = Typography

const Login = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)

    const onFinish = async (valData) => {
        setLoader(true)
        let { user, token, success, message } = await Request.login(valData)
        setLoader(false)
        if (success) {
            dispatch({
                type: 'SET_CURRENT_USER',
                payload: { ...user, token }
            })
            dispatch(push('/dashboard'))
        }
        else {
            notification.error({
                message
            })
        }
        setLoader(false)
    }

    const requiredRule = [{ required: true, message: 'Please input field' }]

    return (
        <div className='loginWrapper'>
            <div className='signupContainer'>
                <div className='signupFormWrapper'>
                    <div className='signupTitle'>Set up your Business Account</div>
                    <Form form={form} onFinish={onFinish} size='large'>
                        <Space direction='vertical' style={{ width: '100%', marginTop: '2em' }}>
                            <Form.Item name='businessName' rules={requiredRule}>
                                <Input placeholder='Business Name' />
                            </Form.Item>
                            <Form.Item name='businessType' rules={requiredRule}>
                                <Select placeholder='Business Type'>
                                    <Option value="Electronics">Electronics</Option>
                                    <Option value="Grocery">Grocery</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name='pin' rules={requiredRule}>
                                <Input.Password visibilityToggle placeholder='Create Pin' />
                            </Form.Item>
                            <Form.Item name='confirm-pin' rules={requiredRule}>
                                <Input.Password visibilityToggle placeholder='Verify Pin' />
                            </Form.Item>
                        </Space>
                        <div className='loginBtn flexEnd'>
                            <Button size='large' shape='circle' loading={loader} htmlType='submit' type='primary' icon={<ArrowRightOutlined />} />
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default Login