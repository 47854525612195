import { combineReducers } from "redux";
import global from "./global";
import theme from "./theme";
import findData from "./find";
import putData from "./put";
import sData from "./serverD";

import { connectRouter, routerMiddleware } from "connected-react-router";

export default (history) =>
  combineReducers({
    theme,
    global,
    findData,
    sData,
    putData,
    router: connectRouter(history),
  });
