import react, { useEffect, useState } from 'react'
import { Form, notification, Card } from 'antd';
import FormItem from '../../components/FormItem'
import './styles.less'
import { useDispatch } from 'react-redux';
import Request from '../../request';
import { getUrlParams } from '../../routes';
import _ from 'lodash'
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const Demo = (props) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)
    const [params, setParams] = useState('')
    const [data, setData] = useState({})
    const onFinish = async (values) => {
        let valData = _.cloneDeep(values)

        if (params?.id) {
            setLoader(true)
            let { user, token, success, message, data } = await Request.updatePlans(params?.id, { ...valData })

            setLoader(false)
            if (success) {
                notification.success({
                    message: message
                })
            } else {
                notification.error({
                    message: message || "Failed",
                    description: JSON.stringify(data).replace('[', '').replace('{', '').replace(']', '').replace('}', '')

                })
            }
        } else {

            setLoader(true)
            let { user, token, success, message, data } = await Request.addPlans(values)
            setLoader(false)
            if (success) {
                notification.success({
                    message: message
                })
                form.resetFields()
            } else {
                notification.error({
                    message: message || "Failed",
                    description: JSON.stringify(data).replace('[', '').replace('{', '').replace(']', '').replace('}', '')

                })
            }
        }
    }
    const requiredRule = [{ required: true, message: 'Please input field' }]
    const apiFunction = async () => {
        let params = getUrlParams('editPlan', window.location.pathname)

        if (params?.id) {
            setParams(params)
            let { user, token, success, message, data } = await Request.getPlans(params?.id)
            if (success) {
                form.setFieldsValue({ ...data })
            } else {
                notification.error({
                    message: message || "Failed"
                })
            }

        }
    }
    useEffect(() => {
        apiFunction()
    }, [props])
    const normFile = (e) => {

        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    }
    return (
        <Card style={{ flex: 1 }}>
            <Form
                {...layout}
                form={form}
                className="formMain"
                onFinish={onFinish}
            >
                <FormItem
                    label={'Plan Name'}
                    name="planname"
                    rules={requiredRule}
                />

                <FormItem
                    label={'Description'}
                    name="plandescription"


                />
                <FormItem
                    label={'Month Price'}
                    name="monthprice"
                    inputType={'NUMBERPRECISION'}
                    width={120}
                    rules={requiredRule}

                />
                <FormItem
                    label={'Year Price'}
                    name="yearprice"
                    inputType={'NUMBERPRECISION'}
                    width={120}
                    rules={requiredRule}

                />
                <FormItem
                    label={'Free Minutes'}
                    inputType={'NUMBER'}
                    name="freeminutes"
                    width={120}
                    rules={requiredRule}

                />
                <FormItem
                    label={'Data Storage'}
                    inputType={'NUMBER'}
                    width={120}
                    name="datastorage"
                />
                <FormItem
                    label={'Maximum Users'}
                    inputType={'NUMBER'}
                    width={120}
                    name="maximumallowedusers"
                    rules={requiredRule}
                />

                <FormItem
                    label={'Free Numbers'}
                    inputType={'NUMBER0'}
                    width={120}
                    name="freenumbers"

                />
                <FormItem
                    label={'Recording Storage'}
                    inputType={'NUMBER'}
                    width={120}
                    name="callrecordingstorage"
                />
                <FormItem
                    label={'After Hour Routing'}
                    inputType={'SWITCH'}
                    name="afterhourrouting"
                />
                <FormItem
                    label={'Agent Blocking'}
                    inputType={'SWITCH'}
                    name="agentblocking"
                />
                <FormItem
                    label={'Automatic Voicemail'}
                    inputType={'SWITCH'}
                    name="automaticvoicemail"
                />

                <FormItem
                    label={'Call Barging'}
                    inputType={'SWITCH'}
                    name="callbarging"
                />

                <FormItem
                    label={'Call Blocking'}
                    inputType={'SWITCH'}
                    name="callblocking"
                />

                <FormItem
                    label={'Call Conference'}
                    inputType={'SWITCH'}
                    name="callconference"
                />

                <FormItem
                    label={'Call Cost Limit'}
                    inputType={'SWITCH'}
                    name="callcostlimit"
                />

                <FormItem
                    label={'Call Hold with Music'}
                    inputType={'SWITCH'}
                    name="callholdwithmusic"
                />

                <FormItem
                    label={'Call Logs'}
                    inputType={'SWITCH'}
                    name="calllogs"
                />

                <FormItem
                    label={'Call Notes'}
                    inputType={'SWITCH'}
                    name="callnotes"
                />

                <FormItem
                    label={'Call Priority'}
                    inputType={'SWITCH'}
                    name="callpriority"
                />

                <FormItem
                    label={'Call Queues'}
                    inputType={'SWITCH'}
                    name="callqueues"
                />

                <FormItem
                    label={'Call Recording'}
                    inputType={'SWITCH'}
                    name="callrecording"
                />

                <FormItem
                    label={'Call Reminder'}
                    inputType={'SWITCH'}
                    name="callreminder"
                />

                <FormItem
                    label={'Call Scheduling'}
                    inputType={'SWITCH'}
                    name="callscheduling"
                />

                <FormItem
                    label={'Call Whispering'}
                    inputType={'SWITCH'}
                    name="callwhispering"
                />

                <FormItem
                    label={'Carrier Switch'}
                    inputType={'SWITCH'}
                    name="carrierswitch"
                />

                <FormItem
                    label={'CDR Report'}
                    inputType={'SWITCH'}
                    name="cdrreport"
                />

                <FormItem
                    label={'CLI'}
                    inputType={'SWITCH'}
                    name="cli"
                />

                <FormItem
                    label={'Concurrent Calls'}
                    inputType={'SWITCH'}
                    name="concurrentcalls"
                />

                <FormItem
                    label={'Contact'}
                    inputType={'SWITCH'}
                    name="contact"
                />

                <FormItem
                    label={'Country Blocking'}
                    inputType={'SWITCH'}
                    name="countryblocking"
                />

                <FormItem
                    label={'Custom Carrier Setup'}
                    inputType={'SWITCH'}
                    name="customcarriersetup"
                />
                <FormItem
                    label={'Custom Report'}
                    inputType={'SWITCH'}
                    name="customreport"
                />
                <FormItem
                    label={'Custom Welcome Message'}
                    inputType={'SWITCH'}
                    name="customwelcomemessage"
                />
                <FormItem
                    label={'Dashboard'}
                    inputType={'SWITCH'}
                    name="dashboard"
                />

                <FormItem
                    label={'Default CLI'}
                    inputType={'SWITCH'}
                    name="defaultcli"
                />
                <FormItem
                    label={'Default Country Setting'}
                    inputType={'SWITCH'}
                    name="defaultcountrysetting"
                />
                <FormItem
                    label={'Delete Call Log'}
                    inputType={'SWITCH'}
                    name="deletecalllog"
                />
                <FormItem
                    label={'Departments'}
                    inputType={'SWITCH'}
                    name="departments"
                />
                <FormItem
                    label={'DNC'}
                    inputType={'SWITCH'}
                    name="dnc"
                />
                <FormItem
                    label={'Mail for Voicemail'}
                    inputType={'SWITCH'}
                    name="emailnotificationforvoicemail"
                />
                <FormItem
                    label={'Forward to Device'}
                    inputType={'SWITCH'}
                    name="forwardtodevice"
                />
                <FormItem
                    label={'Forward to Multiple Device'}
                    inputType={'SWITCH'}
                    name="forwardtomultipledevice"
                />

                <FormItem
                    label={'Global Contact'}
                    inputType={'SWITCH'}
                    name="globalcontact"
                />
                <FormItem
                    label={'Holiday Routing'}
                    inputType={'SWITCH'}
                    name="holidayrouting"
                />
                <FormItem
                    label={'Intelligent Reporting'}
                    inputType={'SWITCH'}
                    name="intelligentreporting"
                />
                <FormItem
                    label={'Internal Team Calling'}
                    inputType={'SWITCH'}
                    name="internalteamcalling"
                />
                <FormItem
                    label={'IP Whitelisting'}
                    inputType={'SWITCH'}
                    name="ipwhitelisting"
                />
                <FormItem
                    label={'Last Call Summary'}
                    inputType={'SWITCH'}
                    name="lastcallsummary"
                />
                <FormItem
                    label={'Mobile App'}
                    inputType={'SWITCH'}
                    name="mobileapp"
                />
                <FormItem
                    label={'Post Call Survey'}
                    inputType={'SWITCH'}
                    name="postcallsurvey"
                />
                <FormItem
                    label={'Power Dialer'}
                    inputType={'SWITCH'}
                    name="powerdialer"
                />
                <FormItem
                    label={'Reports on Email'}
                    inputType={'SWITCH'}
                    name="reportsonemail"
                />
                <FormItem
                    label={'SMS'}
                    inputType={'SWITCH'}
                    name="sms"
                />
                <FormItem
                    label={'Two Factor Auth'}
                    inputType={'SWITCH'}
                    name="twofactorauthentication"
                />
                <FormItem
                    label={'Unlimited Calls'}
                    inputType={'SWITCH'}
                    name="unlimitedcalls"
                />
                <FormItem
                    label={'User Right Customization'}
                    inputType={'SWITCH'}
                    name="userrightscustomization"
                />
                <FormItem
                    label={'Voicemail Custom Greeting'}
                    inputType={'SWITCH'}
                    name="voicemailwithcustomgreeting"
                />
                <FormItem
                    label={'Warm, Blind Transfer'}
                    inputType={'SWITCH'}
                    name="warmandblindtransfer"
                />
                <FormItem
                    label={'Working Hours'}
                    inputType={'SWITCH'}
                    name="workinghours"
                />
                <FormItem
                    label={'Active'}
                    inputType={'SWITCH'}
                    name="active"
                />
                <FormItem
                    label={'Preview Dialer'}
                    inputType={'SWITCH'}
                    name="previewdialer"
                />
                <FormItem
                    label={'Predictive Dialer'}
                    inputType={'SWITCH'}
                    name="predictivedialer"
                />
                <FormItem
                    label={'Add Ons'}
                    inputType={'SWITCH'}
                    name="addons"
                />
                <FormItem
                    label={'Custom Plan?'}
                    inputType={'SWITCH'}
                    name="customplan"
                />

                <div className="marginTop" style={{ flex: '100%' }}>
                    <FormItem
                        inputType="BUTTON"
                        tailLayout={tailLayout}
                        type="primary"
                        loading={loader}
                        title="SUBMIT"
                        style={{ margin: 'auto' }}
                        htmlType="submit" />
                </div>
            </Form>
        </Card >
    );
};

export default Demo