import { useState, lazy, useEffect } from "react";
import {
  Card,
  Form,
  Tooltip,
  notification,
  Radio,
  message,
  Table,
  Tabs,
  Button,
  Input,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  AudioOutlined,
  DeleteColumnOutlined,
  SearchOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import async from "async";
import _ from "lodash";
import FormItem from "../../components/FormItem";
import FilterTable from "../../components/Table";
const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [dataSource, setDataSource] = useState(null);
  const [loading, setLoader] = useState(false);
  const [docTypePermissions, setDocTypePermissions] = useState("");
  const [countries, setCountries] = useState([]);
  const [form] = Form.useForm();

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  useEffect(() => {
    currentUser?.message.find((key, val) => {
      if (key.permissionType == "Document Types") {
        setDocTypePermissions(key);
      }
    });
  }, []);

  // const onSearch = value => console.log(value);

  const deleteDocumentType = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } =
          await Request.deleteDocumentType(fields?.id, {});
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "Failed",
          });
          apiFunction();
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };
  const edit = (record) => {
    dispatch(getPushPathWrapper("Preferences.editDocumentType", record));
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      search: true,
    },
    {
      title: "Document Type",
      dataIndex: "acceptedas",
      key: "acceptedas",
      width: 200,
      render: (val, r) => {
        return val == "id"
          ? "ID Proof"
          : val == "add"
          ? "Address Proof"
          : "Both";
      },
    },
    {
      title: "Allowed countries",
      dataIndex: "allowedcountries",
      key: "allowedcountries",
      render: (val, r) => {
        return (
          <>
            {_.map(val, (value) =>
              _.map(countries, (count) =>
                value == count?.id ? <Tag>{count?.countryname}</Tag> : ""
              )
            )}
          </>
        );
      },
    },
    // {
    //     title: 'Created At',
    //     dataIndex: 'createdon',
    //     key: 'createdon',
    //     render: (val, r) => {
    //         return <Tag>{moment(val).format('DD-MM-YYYY')}</Tag>
    //     }
    // },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 180,
      fixed: "right",
      render: (val, record) => {
        return (
          <div className="flex">
            {docTypePermissions?.deleteStatus ? (
              <div>
                <DeleteOutlined
                  style={{ color: "red", fontSize: 20 }}
                  onClick={() => deleteDocumentType(record)}
                />
              </div>
            ) : (
              <></>
            )}
            {docTypePermissions?.updateStatus ? (
              <div style={{ marginLeft: 10 }}>
                <EditOutlined
                  style={{ color: "primary", fontSize: 20 }}
                  onClick={() => edit(record)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];
  const apiFunction = async (filter) => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.allDocumentType(
      { ...filter }
    );
    setLoader(false);
    if (success) {
      setDataSource(data);
    } else {
      // notification.error({
      //     message: message || "Failed"
      // })
    }
  };
  const getFunction = async () => {
    let { user, token, success, data } = await Request.getCountries();
    if (success) {
      setCountries(() => data);
    } else {
      setCountries(() => []);
    }
  };
  const requiredRule = [{ required: true, message: "Please input field" }];
  useEffect(() => {
    getFunction();
    // apiFunction()
  }, []);
  return (
    <div>
      <div className="flex jcsb marginRight aic" style={{flexDirection: 'column'}}>
        <div>
          <Form form={form} onFinish={apiFunction}>
            <div className="flex aic" style={{ paddingTop: "14px" }}>
              <FormItem
                placeholder={"Allowed Countries"}
                name="allowedcountries"
                inputType={"SELECT"}
                rules={requiredRule}
                // mode={'multiple'}
                style={{ maxWidth: '400px'}}
                options={_.map(countries, (val) => {
                  return { label: val?.countryname, value: val?.id };
                })}
              />
              <FormItem
                inputType="BUTTON"
                type="primary"
                buttonStyle={{ width: 100 }}
                loading={loading}
                title="Search"
                htmlType="submit"
              />
            </div>
          </Form>
        </div>
        {docTypePermissions?.createStatus ? (
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            onClick={() =>
              dispatch(getPushPathWrapper("Preferences.addDocumentType"))
            }
          >
            Add Document Type
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="marginTop">
        {dataSource ? (
          <FilterTable
            bordered
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            scroll={{ x: "auto" }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default HomeView;
