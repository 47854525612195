import React, { useEffect, useState } from "react";
import moment from "moment";
import async from "async";
import Request from "../../request";
import { Tag, Button, DatePicker } from "antd";
import FormItem from "../../components/FormItem";
import FilterTable from "../../components/Table";
import _ from "lodash";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const DepartmentListing = () => {
  const [loading, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [numbers, setNumber] = useState([]);
  const [customerId, setCustomerId] = useState();

  const apiFunction = async (filter) => {
    let newFilter = _.cloneDeep(filter);
    if (newFilter.date) {
      newFilter = {
        ...newFilter,
        startDate: newFilter?.date[0],
        endDate: newFilter?.date[1],
      };
      delete newFilter.date;
    } else {
      delete newFilter.date;
    }

    setLoader(true);
    let { user, token, success, message, data } =
      await Request.fetchDipartmentListing(newFilter);
    setLoader(false);
    if (success && data[0] != null) {
      setDataSource(data);
    } else {
      setDataSource([]);
      // notification.error({
      //     message: message || "Failed"
      // })
    }
  };

  useEffect(() => {
    setFilters({ date: [moment().subtract("days", 7), moment()] });
    apiFunction({ date: [moment().subtract("days", 7), moment()] });
    NumberFxn();
  }, []);

  const downloadInv = async (filter) => {
    let newFilter = _.cloneDeep(filter);
    if (newFilter.date) {
      newFilter = {
        ...newFilter,
        startDate: newFilter?.date[0],
        endDate: newFilter?.date[1],
      };
      delete newFilter.date;
    } else {
      delete newFilter.date;
    }

    setLoader(true);
    const data = await Request.downloadDipartmentListing(newFilter);
    setLoader(false);
  };

  const NumberFxn = async () => {
    let { user, token, success, message, data } = await Request.allCustomer();
    if (success) {
      setNumber(() => data);
    } else {
      setNumber(() => []);
    }
  };

  const columns = [
    {
      title: "Dipartment Name",
      dataIndex: "deptname",
      key: "deptname",
      width: 150,
    },
    {
      title: "Dial Mode",
      dataIndex: "dialmode",
      key: "dialmode",
      width: 250,
    },
    {
      title: "History Date",
      dataIndex: "history_date",
      key: "history_date",
      width: 250,

      render: (val, r) => {
        return <Tag>{moment.utc(val).format("DD-MM-YYYY")}</Tag>;
      },
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (val, r) => {
        return val ? "Active" : "Inactive";
      },
    },
  ];

  return (
    <div>
      <div
        className="flex marginRight jcsb marginTop"
        style={{
          justifyContent: "space-between",
          alignItems: "baseline",
          flexWrap: "wrap",
          gap: "0.5rem",
        }}
      >
        <div className="selectUser" style={{ flex: "30%" }}>
          <FormItem
            label={"Customer"}
            name="Customer"
            initialValues=""
            // rules={requiredRule}
            inputType={"SELECT"}
            options={_.map(numbers, (val) => {
              return {
                label: `${val?.auth?.firstname} ${val?.auth?.lastname} (${val?.auth?.email})`,
                value: val?.id,
              };
            })}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, customer: e }));
              apiFunction({ ...filters, customer: e });
            }}
          />
        </div>

        <div className="flex marginRight ">
          <div className="marginRight">
            <RangePicker
              onChange={(v) => setFilters((prev) => ({ ...prev, date: v }))}
              value={filters?.date}
            />
          </div>
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            loading={loading}
            onClick={() => {
              apiFunction(filters);
            }}
          >
            Search
          </Button>
        </div>

        <div className="downloadBtn">
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            loading={loading}
            onClick={() => {
              downloadInv(filters);
            }}
          >
            Download
          </Button>
        </div>
      </div>
      <div className="marginTop">
        <FilterTable
          bordered
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          scroll={{ x: "auto" }}
          pagination={{ pageSize: 25 }}
        />
      </div>
    </div>
  );
};

export default DepartmentListing;
