import react, { useEffect, useState } from 'react'
import { Form, Input, Button, notification, Card } from 'antd';
import FormItem from '../../components/FormItem'
import './styles.less'
import { useDispatch } from 'react-redux';
import Request from '../../request';
import _ from 'lodash'
import { getUrlParams } from '../../routes';
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};

const Demo = (props) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)
    const [params, setParams] = useState(false)
   
    const onFinish = async (valData) => {
        if (params?.id) {
            setLoader(true)
            let { user, token, success, message, data } = await Request.updateCustomerStatus(params?.id, { ...valData })
            // console.log(success, data, message)
            setLoader(false)
            if (success) {
                notification.success({
                    message: message
                })
            } else {
                notification.error({
                    message: message || "Failed"
                })
            }
        } else {
            setLoader(true)
            let { user, token, success, message, data } = await Request.addCustomerStatus(valData)
            setLoader(false)
            if (success) {
                notification.success({
                    message: message
                })
                form.resetFields()
            } else {
                notification.error({
                    message: message || "Failed"
                })
            }
        }
    }

    const requiredRule = [{ required: true, message: 'Please input field' }]
    const apiFunction = async () => {
        let params = getUrlParams('Preferences.editCustomerStatus', window.location.pathname)
        if (params?.id) {
            setParams(params)
            let { user, token, success, message, data } = await Request.getCustomerStatus(params?.id)
            if (success) {
                let mainData = data
                form.setFieldsValue({ ...mainData })
            } else {
                notification.error({
                    message: message || "Failed"
                })
            }

        }
    }

    useEffect(async () => {
        apiFunction()
    }, [props])
    return (
        <Card style={{ flex: 1 }}>
            <Form
                {...layout}
                form={form}
                onFinish={onFinish}
            >
                <FormItem
                    label={'Name'}
                    name="name"
                    rules={requiredRule}
                />
                 <FormItem
                    label={'Description'}
                    name="description"
                    // rules={requiredRule}
                />
                <div >
                    <FormItem
                        inputType="BUTTON"
                        tailLayout={tailLayout}
                        type="primary"
                        buttonStyle={{ width: 180, marginTop: 50 }}
                        loading={loader}
                        title="SUBMIT"
                        htmlType="submit" />
                </div>
            </Form>
        </Card >
    );
};

export default Demo