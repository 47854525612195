import { useState, lazy, useEffect } from "react";
import {
  Card,
  Space,
  Drawer,
  notification,
  Popover,
  message,
  Table,
  Tabs,
  Button,
  Input,
  Tag,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  AudioOutlined,
  CloseCircleOutlined,
  CheckSquareOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import async from "async";
import FilterTable from "../../components/Table";
import _ from "lodash";
import { ReactComponent as ADDRESS } from "../../assets/address.svg";
import { ReactComponent as ID } from "../../assets/id.svg";

const { Search } = Input;

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [document, setDocument] = useState([]);
  const [images, setImages] = useState("");
  const [loading, setLoader] = useState(false);

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  // const onSearch = value => console.log(value);

  const columns = [
    {
      title: "First name",
      dataIndex: "firstname",
      key: "firstname",
      width: 150,
      // fixed: "left",
      search: true,
    },
    {
      title: "Last name",
      dataIndex: "lastname",
      key: "lastname",
      width: 150,
      // fixed: "left",
      search: true,
    },
    {
      title: "business name",
      dataIndex: "businessname",
      key: "businessname",
      search: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
      search: true,
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      search: true,
    },
    // {
    //     title: 'Country',
    //     dataIndex: 'callercountry',
    //     key: 'callercountry',
    // },
    // {
    //     title: 'Status',
    //     dataIndex: 'callerstatus',
    //     key: 'callerstatus',
    // },

    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
    },
    {
      title: "Active",
      dataIndex: "isactive",
      key: "isactive",
      render: (val, r) => {
        return (
          <Tag
            color={val ? "#87d068" : "#f50"}
            style={{
              borderRadius: 30,
              width: 60,
              justifyContent: "center",
              margin: "auto",
            }}
          >
            {val ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdon",
      key: "createdon",
      render: (val, r) => {
        return <Tag>{moment(val).format("DD-MM-YYYY")}</Tag>;
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 140,
      fixed: "right",
      render: (val, record) => {
        return (
          <div className="flex">
            <div>
              <Tooltip title="Docs">
                <EyeOutlined
                  style={{ fontSize: 20 }}
                  onClick={() => {
                    showDrawer();
                    setDocument(record?.docs);
                  }}
                />
              </Tooltip>
            </div>
            <div style={{ marginLeft: 10 }}>
              <Tooltip title="Verify">
                <CheckSquareOutlined
                  style={{ color: "green", fontSize: 20 }}
                  onClick={() => accept(record)}
                />
              </Tooltip>
            </div>

            <div style={{ marginLeft: 10 }}>
              <Tooltip title="Reject">
                <CloseCircleOutlined
                  style={{ color: "red", fontSize: 20 }}
                  onClick={() => rejectDocument(record)}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
  const accept = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Verify",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } =
          await Request.verfiyCustomer(fields?.id, {});
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "success",
          });
          apiFunction();
          apiFunction2();
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };
  const reject = async (fields) => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.rejectCustomer(
      fields?.id,
      {}
    );
    setLoader(false);
    if (success) {
      notification.success({
        message: message || "success",
      });
      apiFunction();
    } else {
      notification.error({
        message: message || "Failed",
      });
    }
  };
  const rejectDocument = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to reject",
      onOk: async () => {
        confirmBoxFxn(fields);
      },
    });
  };
  const confirmBoxFxn = (doc) => {
    confirmBox(dispatch, {
      message: "Select the Document",
      onOk: () => {
        reject(doc?.docs[1]);
      },
      onCancel: () => {
        reject(doc?.docs[0]);
      },
      okText: `${doc?.docs[1]?.type?.name}(${
        doc?.docs[1]?.type?.acceptedas == "add" ? "Address Proof" : "ID Proof"
      })`,
      cancelText: `${doc?.docs[0]?.type?.name}(${
        doc?.docs[0]?.type?.acceptedas == "add" ? "Address Proof" : "ID Proof"
      })`,
    });
  };
  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } =
      await Request.allCustomerWithDocs({ doc_status: "uploaded" });
    setLoader(false);
    let newData = [];
    if (success) {
      async.forEach(
        data,
        (val, cb) => {
          let obj = {
            ...val?.auth,
            ...val,
          };
          newData.push(obj);
          cb();
        },
        () => {
          setDataSource(newData);
        }
      );
    } else {
      // notification.error({
      //     message: message || "Failed"
      // })
    }
  };
  const apiFunction2 = async () => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.allCustomer();
    setLoader(false);
    let newData = [];
    if (success) {
      async.forEach(
        data,
        (val, cb) => {
          if (val?.verified && val?.server == null) {
            let obj = {
              ...val?.auth,
              ...val,
            };
            newData.push(obj);
            cb();
          } else {
            cb();
          }
        },
        () => {
          setDataSource(newData);
        }
      );
    } else {
      // notification.error({
      //     message: message || "Failed",
      //     description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
      // })
    }
  };
  const apiFunction1 = async (val) => {
    let { user, token, success, message, data } = await Request.getFiles(
      { context: "document", id: val?.id },
      val
    );
    if (success) {
    } else {
    }
  };
  useEffect(() => {
    apiFunction();
  }, []);
  return (
    <div>
      <div className="marginTop">
        <div className="Customerapprovals">Customers Waiting for Approvals</div>
        <FilterTable
          // size="small"
          // bordered
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          scroll={{ x: "auto" }}
        />
      </div>
      <Drawer
        title="Documents"
        placement="right"
        closable={false}
        width={400}
        onClose={onClose}
        visible={visible}
      >
        {_.map(document, (val) => {
          return (
            <div className="documentParent">
              {val?.type?.acceptedas == "id" ? (
                <ID style={{ width: 120, height: 120 }} />
              ) : (
                <ADDRESS style={{ width: 100, height: 100 }} />
              )}
              <div>
                <span className="spanName">{val?.type?.name}</span> (
                {val?.type?.acceptedas == "id" ? "ID Proof" : "Address Proof"})
                <Button
                  type="link"
                  onClick={() => apiFunction1(val)}
                  style={{
                    background: "#dfe6ed",
                    borderRadius: "5",
                    marginLeft: 2,
                  }}
                >
                  View File
                </Button>
              </div>
            </div>
          );
        })}
        {images}
      </Drawer>
    </div>
  );
};

export default HomeView;
