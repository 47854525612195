import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Layout, Menu, Drawer } from "antd";
import "./styles.less";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
// import logo from '../assets/logoWhite.png'
import Media from "react-media";
import DashboardHeader from "../components/header";
import SiderMenu from "../components/siderMenu";
import Modal from "antd/lib/modal/Modal";
import Request from "../request";
const { Header, Content } = Layout;

export default (props) => {
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);
  const [modal, setModalVisible] = useState(false);
  const { currentUser, showPlan, headerToggle } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    showPlan: state.global.showPlan,
    headerToggle: state.global.headerToggle,
  }));

  useEffect(() => {
    if (!currentUser) {
      dispatch(push(""));
    }
    checkFunctionForPlans();
  }, [currentUser]);

  const checkFunctionForPlans = async () => {
    let { data, success } = await Request.getProfile();
    if (success) {
      if (data?.plan?.id) {
        setModalVisible(false);
      } else {
        setModalVisible(true);
      }
    } else setModalVisible(true);
  };
  const toggle = () => {
    setCollapsed((prev) => !prev);
    dispatch({
      type: "SET_HEADER",
      payload: false,
    });
  };
  useEffect(() => {
    setCollapsed(headerToggle);
  }, [headerToggle]);
  const { children, menuData, path, item } = props;
  return (
    <Layout>
      <Media queries={{ small: { maxWidth: 950 } }}>
        {(matches) =>
          matches.small ? (
            <Drawer
              visible={collapsed}
              placement="left"
              onClose={toggle}
              closable={false}
              getContainer={false}
              width = 'auto'
              height = 'auto'
              zIndex = {999}
              bodyStyle={{
                padding: 0,
              }}
            >
              <SiderMenu
                isMobile={matches.small}
                menuData={menuData}
                path={path}
                collapsed={!collapsed}
              />
            </Drawer>
          ) : (
            <SiderMenu
              isMobile={matches.small}
              menuData={menuData}
              path={path}
              collapsed={collapsed}
            />
          )
        }
      </Media>

      <Layout className={"layout"}>
        <DashboardHeader collapsed={collapsed} toggle={toggle} item={item} />
        <Content className={"content"}>{children}</Content>
      </Layout>
    </Layout>
  );
};
