import React, { useState } from "react";
import "./styles.less";
import {
  Typography,
  Form,
  Switch,
  Menu,
  Badge,
  Avatar,
  Popover,
  List,
  Button,
  Input,
  Alert,
  notification
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { confirmBox } from "../../utils";
import {
  ArrowLeftOutlined,
  CaretRightOutlined,
  CheckOutlined,
  LineHeightOutlined,
  PhoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { LeftOutlined } from "@ant-design/icons";
import { goBack, push } from "connected-react-router";
import { UserOutlined } from "@ant-design/icons";
import { findData } from "../../action";
import { Link, useHistory } from "react-router-dom";
import Request from "../../request"

const { Title, Text } = Typography;
const { SubMenu } = Menu;

const HomeHeader = ({ item }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));

  const [state, setState] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loader, setLoader] = useState(false);

  const myState = useSelector((state) => state.findData);

  let SearchData = (searchTerm) => {
    dispatch(findData(searchTerm));
  };

  let enterData = (searchTerm) => {
    // <Link to="/Search"> </Link>
    history.push("/Search");
    dispatch(findData(searchTerm));
  };
  // console.log(myState, "findData")

  const handleClick = (e) => {
    // console.log('click ', e);
    setState(() => e.key);
  };

  const toggleHeader = () => {
    dispatch({
      type: "SET_HEADER",
      payload: true,
    });
  };

  const Logout = async (valData) => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.logout();
    setLoader(false);
    if (success) {
      dispatch({ type: "LOGOUT" });
      dispatch({ type: "SET_CURRENT_USER", payload: null });
      notification.success({
        message: message,
      });
    } else {
      notification.error({
        message: `${message}` || "Failed",
      });
    }
  };

  return (
    <div className={"header"}>
      <div className={"burger-menu-container"} onClick={() => toggleHeader()}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={"headText"} style={{ width: "-webkit-fill-available" }}>
        <div className="flex">
          <div onClick={() => dispatch(goBack())}>
            <LeftOutlined
              style={{ fontSize: 20, marginRight: 10, color: "#fff" }}
            />
          </div>
          <div className={"heading"}>{item?.title}</div>
        </div>
      </div>

      {/* Search input field */}

      <Input
        onPressEnter={() => enterData(searchTerm)}
        placeholder="Search"
        suffix={
          searchTerm ? (
            <Link to="/Search">
              <SearchOutlined
                style={{ color: "rgba(0,0,0,.45)" }}
                onClick={() => SearchData(searchTerm)}
              />{" "}
            </Link>
          ) : (
            <SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />
          )
        }
        style={{ borderRadius: "4px", minWidth: "90px" }}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
      />

      <div className={"headTools flex"}>
        {/* <div className={'marginMore'}>
                <Button type="primary" icon={<PhoneOutlined />}>Open Dailer</Button>
            </div> */}
        {/* <Badge dot={true} className={'marginMore'}>
                <NotificationIcon />
            </Badge> */}
        {/* <div className={'username marginRight'}>{currentUser?.email}</div> */}
        {/* <Menu onClick={handleClick} selectedKeys={[state]} mode="horizontal" >
                <SubMenu key="SubMenu" title={currentUser?.auth?.email}>
                    <Menu.Item key="logout" onClick={() => confirmBox(dispatch, {
                        message: 'Are you sure you want to Logout',
                        onOk: () => {
                            dispatch({ type: 'LOGOUT' })
                        }
                    })} >
                        Log Out
                    </Menu.Item>
                </SubMenu>
            </Menu> */}
        <Menu
          mode="horizontal"
          // inlineCollapsed={false}
          expandIcon={false}
          style={{ borderBottom: "0" }}
          overflowedIndicator={false}
          onClick={() => handleClick}
          selectedKeys={[state]}
          className="username"
          triggerSubMenuAction="click"
          title="Details"
        >
          <SubMenu
            key="SubMenu"
            title={
              <Avatar
                style={{ backgroundColor: "#2fd838" }}
                icon={<UserOutlined />}
              />
            }
          >
            <Menu.Item key="email">
              <span>
                {currentUser?.auth?.email}
              </span>
            </Menu.Item>
            <Menu.Item
              key="logout"
              onClick={() =>
                confirmBox(dispatch, {
                  message: "Are you sure you want to Logout",
                  onOk: () => {
                    // dispatch({ type: "SET_CURRENT_USER", payload: null });
                    // dispatch({ type: "LOGOUT" });
                    Logout();
                  },
                })
              }
            >
              Log Out
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </div>
  );
};
export default HomeHeader;
