import { useState, lazy, useEffect } from "react";
import {
  notification,
  Drawer,
  Input,
  Tag,
  Tooltip,
  Modal,
  Button,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  AudioOutlined,
  DeleteOutlined,
  CloudServerOutlined,
} from "@ant-design/icons";
import Request from "../../request";
import moment from "moment";
import _ from "lodash";
import { confirmBox } from "../../utils";
import async from "async";
import FilterTable from "../../components/Table";

const { Search } = Input;

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [dataSource, setDataSource] = useState([]);

  const [loader, setLoader] = useState(false);
  const [filters, setFilters] = useState({});
  const [customerId, setCustomerId] = useState({});
  const [server, setServer] = useState({});

  const [visible, setVisible] = useState(false);

  const showModal = (id) => {
    setCustomerId(id);
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const getServerList = async () => {
    setLoader(true);
    let { user, token, success, message, data } =
      await Request.allAsteriskServer();
    setLoader(false);
    let newData = [];
    if (success) {
      async.forEach(
        data,
        (val, cb) => {
          if (val?.active && val?.shareable) {
            let obj = {
              ...val?.auth,
              ...val,
            };
            newData.push(obj);
            cb();
          } else {
            cb();
          }
        },
        () => {
          setServer(newData);
        }
      );
    } else {
      notification.error({
        message: message || "Failed",
        description: JSON.stringify(data)
          ?.replace("[", "")
          ?.replace("{", "")
          ?.replace("]", "")
          ?.replace("}", ""),
      });
    }
  };

  const onFinish = async (filters) => {
    if (!filters?.server) {
      notification.warning({
        message: "Required Fields",
        description: "Please Choose Server",
      });
      return;
    }
    setLoader(true);
    let { user, token, success, message, data } = await Request.allocateServer(
      customerId,
      { ...filters }
    );
    setLoader(false);
    if (success) {
      notification.success({
        message: message || "added",
      });
      setFilters({ server: undefined });
      setCustomerId({});
      setVisible(false);
      apiFunction();
    } else {
      notification.error({
        message: message || "Failed",
        description: JSON.stringify(data)
          ?.replace("[", "")
          ?.replace("{", "")
          ?.replace("]", "")
          ?.replace("}", ""),
      });
    }
  };

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  const deleteUser = (fields) => {
    // confirmBox(dispatch, {
    //     message: 'Are you sure you want to Delete',
    //     onOk: async () => {
    //         setLoader(true)
    //         let { user, token, success, message, data } = await Request.deleteCustomer(fields)
    //         setLoader(false)
    //         if (success) {
    //             notification.success({
    //                 message: message || "success"
    //             })
    //             apiFunction()
    //         } else {
    //             notification.error({
    //                 message: message || "Failed"
    //             })
    //         }
    //     }
    // })
  };
  const columns = [
    {
      title: "First name",
      dataIndex: "firstname",
      key: "firstname",
      width: 250,
      render: (value, record) => {
        return (
          <>
            {value} {record?.lastname}
          </>
        );
      },
    },
    {
      title: "business name",
      dataIndex: "businessname",
      key: "businessname",
      width: 250,
      search: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
      search: true,
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      width: 250,
      search: true,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 150,
      render: (val, record) => {
        return (
          <div className="flex" style={{ justifyContent: "space-evenly" }}>
            <div>
              <Tooltip title="delete">
                <DeleteOutlined
                  style={{ fontSize: 20, color: "red" }}
                  onClick={() => {
                    deleteUser(record?.id);
                  }}
                />
              </Tooltip>
            </div>
            <div style={{ marginLeft: 10 }}>
              <Tooltip title="Server Allocation">
                <CloudServerOutlined
                  style={{ fontSize: 20 }}
                  onClick={() => {
                    getServerList();
                    showModal(record?.id);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.allCustomer();
    setLoader(false);
    let newData = [];
    if (success) {
      async.forEach(
        data,
        (val, cb) => {
          if (val?.verified && val?.server == null) {
            let obj = {
              ...val?.auth,
              ...val,
            };
            newData.push(obj);
            cb();
          } else {
            cb();
          }
        },
        () => {
          setDataSource(newData);
        }
      );
    } else {
      // notification.error({
      //     message: message || "Failed",
      //     description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
      // })
    }
  };
  useEffect(() => {
    apiFunction();
  }, []);
  return (
    <div>
      <div className="marginTop">
        <div className="Customerapprovals">
          Customers Waiting for Server Allocation
        </div>
        <FilterTable
          bordered
          dataSource={dataSource}
          columns={columns}
          loading={loader}
          scroll={{ x: 'auto' }}
        />
      </div>

      <Modal
        title="Select server for Allocation"
        visible={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          showSearch
          allowClear={true}
          key={filters?.server}
          onChange={(val) => setFilters((prev) => ({ ...prev, server: val }))}
          style={{
            justifyContent: "center",
            width: "100%",
            border: "1px solid #e1e3e8",
            borderRadius: "4px",
          }}
          placeholder="Select Server"
          value={filters?.server}
          loading={loader}
          options={_.map(server, (val) => {
            return { label: `${val?.serverip} - ${val?.fqdn}`, value: val?.id };
          })}
        />
        <div
          className="flex aic marginTop jcc"
          style={{ justifyContent: "center" }}
        >
          <Button
            type="primary"
            loading={loader}
            onClick={() => onFinish(filters)}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default HomeView;
