import React, { Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import menuData from "../../routes";
import BasicLayout from "../../layouts/basicLayout";
import Login from "../../containers/login/index";
import Search from "./Search";
import SearchedUser from "./SearchedUser";
import UserSearch from "./UserSearch";

// import Signup from '../../containers/signup'
// import OTP from '../../containers/login/otp'

export default (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {menuData.map((item, key) => {
          if (!item.children) {
            return (
              <Route
                exact
                path={item.path}
                key={item.path}
                render={(route) => {
                  return (
                    <BasicLayout
                      path={item.path}
                      menuData={menuData}
                      item={item}
                    >
                      {!!item.component ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          {item.component}
                        </Suspense>
                      ) : (
                        <></>
                      )}
                    </BasicLayout>
                  );
                }}
              />
            );
          }
        })}

        {menuData.map((item, key) => {
          if (item.children) {
            return item.children.map((child, k) => {
              return (
                <Route
                  exact
                  path={child.path}
                  key={child.path}
                  render={(route) => {
                    return (
                      <BasicLayout
                        path={child.path}
                        menuData={menuData}
                        item={child}
                      >
                        {!!child.component ? (
                          <Suspense fallback={<div>Loading...</div>}>
                            {child.component}
                          </Suspense>
                        ) : (
                          <></>
                        )}
                      </BasicLayout>
                    );
                  }}
                />
              );
            });
          }
        })}

        <Route exact path={"/"} key={"login"} render={(route) => <Login />} />
        <Route
          exact
          path={"/login"}
          key={"login"}
          render={(route) => <Login />}
        />

        <Route
          exact
          path={"/Search"}
          key={"search"}
          render={(route) => <Search />}
        />
        <Route
          exact
          path={"/UserSearch"}
          key={"userSearch"}
          render={(route) => <UserSearch />}
        />
        <Route
          exact
          path="/SearchedUser"
          key={"searchedUser"}
          render={(route) => <SearchedUser />}
        />

        {/* <Route exact path={'/signup'} key={'signup'} render={(route) => <Signup />} />
                <Route exact path={'/verify'} key={'otp'} render={(route) => <OTP />} /> */}
      </Switch>
    </Suspense>
  );
};
