import React, { useEffect, useState } from "react";
import moment from "moment";
import async from "async";
import Request from "../../request";
import { Tag, Button, DatePicker } from "antd";
import FilterTable from "../../components/Table";

const { RangePicker } = DatePicker;

const FetchInvoice = () => {
  const [loading, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const apiFunction = async (filter) => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.FInvoice({ startDate: filter?.date[0], endDate: filter?.date[1] });
    setLoader(false);
    let newData = [];
    if (success) {
      async.forEach(
        data,
        (val, cb) => {
          let obj = {
            ...val?.transactions[0],

            ...val,
          };
          newData.push(obj);
          cb();
        },
        () => {
          setDataSource(newData);
        }
      );
    } else {
      // notification.error({
      //     message: message || "Failed"
      // })
    }
  };

  useEffect(() => {
    setFilters({ date: [moment().subtract('days', 7), moment()] });
    apiFunction({ date: [moment().subtract('days', 7), moment()] });
  }, []);

  const downloadInv = async (filter) => {
    setLoader(true);
    const data = await Request.downloadInvoice({ startDate: filter?.date[0], endDate: filter?.date[1] });
    setLoader(false);
  };

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customerFirstName",
      key: "customerFirstName",
      fixed: "left",
      render: (val, r) => {
        return val + " " + r?.customerLastName;
      },
    },
    {
      title: "Order ID",
      dataIndex: "orderid",
      key: "OID",
      width: 150,
    },
    {
      title: "Payment Date",
      dataIndex: "paid_date",
      key: "Payment Date",
      width: 150,

      render: (val, r) => {
        return <Tag>{moment.utc(val).format("DD-MM-YYYY")}</Tag>;
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "createdon",
      key: "createdon",
      width: 250,

      render: (val, r) => {
        return <Tag>{moment.utc(val).format("DD-MM-YYYY")}</Tag>;
      },
    },
    {
      title: "Payment Amount",
      dataIndex: "total_amount",
      key: "T Amount",
      width: 250,
    },

    {
      title: "Payment Mode",
      dataIndex: "mode",
      key: "mode",
      width: 150,
      render: (val, r) => {
        return <>{r?.transactions?.mode}</>;
      },
    },

    {
      title: "Payment Status",
      dataIndex: "status",
      key: "Status",
      width: 150,
      render: (val, r) => {
        return <>{r?.transactions?.status}</>;
      },
    },
    {
      title: "Paid",
      dataIndex: "paid",
      key: "paid",
      width: 150,
      render: (val, r) => {
        return (
          <Tag
            color={val ? "#87d068" : "#f50"}
            style={{
              borderRadius: 30,
              width: 60,
              justifyContent: "center",
              margin: "auto",
            }}
          >
            {val ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "Description",
      width: 150,

      render: (val, r) => {
        return val?.description;
      },
    },
  ];

  return (
    <div>
      <div className="flex marginRight jcsb marginTop">
        <div className="flex marginRight ">
          <div className="marginRight">
            <RangePicker
              onChange={(v) => setFilters((prev) => ({ ...prev, date: v }))}
              value={filters?.date}
            />
          </div>
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            loading={loading}
            onClick={() => {
              apiFunction(filters);
            }}
          >
            Search
          </Button>
        </div>
        <div className="downloadBtn">
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            loading={loading}
            onClick={() => {
              downloadInv({ date: filters.date });
            }}
          >
            Download
          </Button>
        </div>
      </div>
      <div className="marginTop">
        <FilterTable
          bordered
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          scroll={{ x: "auto" }}
        />
      </div>
    </div>
  );
};

export default FetchInvoice;
