import { useState, lazy, useEffect } from "react";
import {
  Card,
  Space,
  Tooltip,
  notification,
  Radio,
  message,
  Table,
  Tabs,
  Button,
  Input,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  AudioOutlined,
  DeleteColumnOutlined,
  SearchOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import async from "async";
import FilterTable from "../../components/Table";

const { Search } = Input;

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoader] = useState(false);
  const [customerStatusPermissions, setCustomerStatusPermissions] =
    useState("");

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  // const onSearch = value => console.log(value);
  useEffect(() => {
    currentUser?.message.find((key, val) => {
      if (key.permissionType == "Customer Status") {
        setCustomerStatusPermissions(key);
      }
    });
  }, []);

  const deleteFxn = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } =
          await Request.deleteCustomerStatus(fields?.id, {});
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "Failed",
          });
          apiFunction();
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };
  const edit = (record) => {
    // console.log(record)
    dispatch(getPushPathWrapper("Preferences.editCustomerStatus", record));
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      search: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    // {
    //     title: 'Created At',
    //     dataIndex: 'createdon',
    //     key: 'createdon',
    //     render: (val, r) => {
    //         return <Tag>{moment(val).format('DD-MM-YYYY')}</Tag>
    //     }
    // },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 180,
      render: (val, record) => {
        return (
          <div className="flex">
            {customerStatusPermissions?.deleteStatus ? (
              <div>
                <DeleteOutlined
                  style={{ color: "red", fontSize: 20 }}
                  onClick={() => deleteFxn(record)}
                />
              </div>
            ) : (
              <></>
            )}
            {customerStatusPermissions?.updateStatus ? (
              <div style={{ marginLeft: 10 }}>
                <EditOutlined
                  style={{ color: "primary", fontSize: 20 }}
                  onClick={() => edit(record)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];
  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } =
      await Request.allCustomerStatus();
    setLoader(false);
    if (success) {
      setDataSource(data);
    } else {
      // notification.error({
      //     message: message || "Failed"
      // })
    }
  };
  useEffect(() => {
    apiFunction();
  }, [customerStatusPermissions?.listStatus]);
  return (
    <div>
      <div className="flex jcsb marginRight">
        <div>
          {/* <Input
                    placeholder="Search"
                    suffix={
                        <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    }
                    style={{ borderRadius: '4px' }}
                /> */}
        </div>
        {customerStatusPermissions?.createStatus ? (
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            onClick={() =>
              dispatch(getPushPathWrapper("Preferences.addCustomerStatus"))
            }
          >
            Add Status
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="marginTop">
        {customerStatusPermissions?.listStatus && (
          <FilterTable
            bordered
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            scroll={{ x: 'auto' }}
          />
        )}
      </div>
    </div>
  );
};

export default HomeView;
