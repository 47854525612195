import axios from "axios";
import { Apiurl, ExcelDownloadFormat, DateFormat } from "./settings";
import {
  fullBrowserVersion,
  browserName,
  osVersion,
  osName,
} from "react-device-detect";
let authAxios = axios.create({
  baseURL: Apiurl,
});

export const getToken = () => {
  return {
    headers: {
      Authorization: "Token " + localStorage.getItem("token"),
      type: "WEB",
      fullbrowserversion: fullBrowserVersion,
      browsername: browserName,
      osversion: osVersion,
      osname: osName,
    },
  };
};

export const getToken1 = () => {
  return {
    headers: {
      Authorization: "Token " + "f123c0a191e4b1aea3f999e2e18869497b8182c9",
      type: "WEB",
      fullbrowserversion: fullBrowserVersion,
      browsername: browserName,
      osversion: osVersion,
      osname: osName,
    },
  };
};

class Request {
  error = (err) => {
    try {
      if (err.response.status === 401) {
        localStorage.clear();
      }
    } catch (e) {}
  };

  // ------------------------------------------- API Start from here --------------------------------------------- //
  getCountries() {
    return new Promise((next, error) => {
      authAxios
        .post("/countries", {})
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updateCountries(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/countries/update/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  signup(data, recaptchaToken) {
    return new Promise((next, error) => {
      authAxios
        .post("/signup", data)
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  checkEmail(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/signup", data)
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  login(data, recaptchaToken) {
    return new Promise((next, error) => {
      authAxios
        .post("/employee/login", data)
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  logout(data, recaptchaToken) {
    return new Promise((next, error) => {
      authAxios
        .post("/employee/logout", data, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  // user api

  getProfile() {
    return new Promise((next, error) => {
      authAxios
        .post("/employee/details", {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updateProfile(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/employee/update", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  addAdmin(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/employee/new", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  allAdmin(data) {
    return new Promise((next, error) => {
      //apoi ni h
      authAxios
        .post("/employee/list", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  superUserPermissions(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/employee/permission/list", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  addPermission(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/employee/new/permission", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updatePermission(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/employee/permission/update/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  deletePermission(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/employee/permission/delete/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  getAdmin(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/employee/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updateAdmin(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/employee/update/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  deleteAdmin(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/employee/delete/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  //Customer
  allCustomer(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/customer/all", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  FInvoice(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/customer/fetchinvoice", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  allCustomerWithDocs(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/customer/docs", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  getSupportUserDashboard(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/employee/dashboard/support/livecall", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  getCustomer(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/userdetails", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updateCustomer(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/updatedetails", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updateActive(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/update/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  deleteCustomer(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/delete/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  allCallers(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/callers", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  //DocumentType
  addDocumentType(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/customer/docs/type/new", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  allDocumentType(data) {
    return new Promise((next, error) => {
      //apoi ni h
      authAxios
        .post("/customer/docs/type", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  getDocumentType(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/docs/type/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updateDocumentType(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/docs/type/update/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  deleteDocumentType(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/docs/type/delete/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  //status
  getCustomerStatus(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/status/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updateCustomerStatus(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/status/update/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  addCustomerStatus(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/customer/status/new", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  allCustomerStatus(data) {
    return new Promise((next, error) => {
      //apoi ni h
      authAxios
        .post("/customer/status", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  deleteCustomerStatus(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/status/delete/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  verfiyCustomer(id, data) {
    return new Promise((next, error) => {
      //apoi ni h
      authAxios
        .post(`/customer/verify/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  rejectCustomer(id, data) {
    return new Promise((next, error) => {
      //apoi ni h
      authAxios
        .post(`/customer/docs/reject/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  //AsteriskServer
  addAsteriskServer(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/server/new", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  allAsteriskServer(data) {
    return new Promise((next, error) => {
      //apoi ni h
      authAxios
        .post("/server", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  getAsteriskServer(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/server/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updateAsteriskServer(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/server/update/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  deletAsteriskServer(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/server/delete/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  Asteriskdata(data) {
    return new Promise((next, error) => {
      //apoi ni h
      authAxios
        .post("/employee/asterisk/system/info", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  getFiles(data, value) {
    return new Promise((next, error) => {
      authAxios
        .post(`/serve`, { ...data }, getToken())
        .then((d) => {
          let format = d?.data?.data?.type;
          var a = document.createElement("a"); //Create <a>
          let mainFile;

          // Check for different formats and set appropriate MIME types
          if (format === "pdf") {
            mainFile = `data:application/pdf;base64,${d?.data?.data?.file}`;
          } else if (format === "png") {
            mainFile = `data:image/png;base64,${d?.data?.data?.file}`;
          } else if (format === "jpeg" || format === "jpg") {
            mainFile = `data:image/jpeg;base64,${d?.data?.data?.file}`;
          } else if (format === "gif") {
            mainFile = `data:image/gif;base64,${d?.data?.data?.file}`;
          } else if (format === "doc" || format === "docx") {
            mainFile = `data:application/msword;base64,${d?.data?.data?.file}`;
          } else if (format === "xls" || format === "xlsx") {
            mainFile = `data:application/vnd.ms-excel;base64,${d?.data?.data?.file}`;
          } // Add more formats as needed...

          let pdfWindow = window.open("");
          pdfWindow.document.write(
            `<iframe width='100%' height='100%' src=${mainFile}></iframe>`
          );
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  //number
  getNumbers(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/numbers/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updateNumber(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/numbers/update/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  getAllNumbers(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/customer/numbers", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  deleteNumbers(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/numbers/superadmin/delete/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  addNumber(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/customer/numbers/new", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  numberDetails(data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/employee/did/list`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  getNumberCode(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/customer/numbers/phonecodes", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  uploadNumber(data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/numbers/upload`, data, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  //Carriers
  getCarriers(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/carriers/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updateCarriers(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/carriers/update/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  getAllCarriers(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/carriers", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  deleteCarriers(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/carriers/delete/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  addCarriers(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/carriers/new", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  //carriers location listcodes
  allListcodes(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/carriers/listcodes", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  //plans
  getPlans(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/plans/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updatePlans(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/plans/update/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  getAllPlans(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/plans", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  deletePlans(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/plans/delete/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  addPlans(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/plans/new", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  //AddOns

  getAllAdOns(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/plans/addons", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  createAdOns(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/plans/new/addons", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  getAdOns(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/plans/addons/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  deleteAdOns(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/plans/delete/addons/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updateAdOns(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/plans//update/addons/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  //incoming
  getAllIncoming(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/carriers/charge/incoming", {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  getIncoming(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/carriers/charge/incoming/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updateIncoming(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/carriers/charge/incoming/update/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  deleteIncoming(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/carriers/charge/incoming/delete/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  addIncoming(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/carriers/charge/incoming/new", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  uploadIncoming(data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/carriers/charge/incoming/upload`, data, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  //outgoing
  getAllOutgoing(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/carriers/charge/outgoing", {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  getOutgoing(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/carriers/charge/outgoing/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  updateOutgoing(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/carriers/charge/outgoing/update/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  deleteOutgoing(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/carriers/charge/outgoing/delete/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  addOutgoing(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/carriers/charge/outgoing/new", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  uploadOutgoing(data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/carriers/charge/outgoing/upload`, data, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  //call history
  callHistory(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/call/log", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  graph(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/dashboard/graph", data, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  filterCallHistory(data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/call/log`, data, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  customerDetail(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/${id}`, data, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  allocateServer(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/allocate/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  downloadInvoice(filter) {
    return new Promise((next, error) => {
      authAxios
        .post(
          `/employee/invoice/download/customer`,
          { ...filter },
          { params: {}, ...getToken(), responseType: "blob" }
        )
        .then((d) => {
          const blob = new Blob([d.data], { type: "application/vnd.ms-excel" });
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.download = `customer-invoice.xls`; // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href);
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  downloadCallHistory(filter) {
    return new Promise((next, error) => {
      authAxios
        .post(`/call/log-download`, { ...filter }, getToken())
        .then((d) => {
          const linkSource =
            "data:" +
            `application/${ExcelDownloadFormat}` +
            ";base64, " +
            d?.data?.data;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = `call-history.${ExcelDownloadFormat}`;
          downloadLink.click();
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  fetchDipartmentListing(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/customer/department/list", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  downloadDipartmentListing(filter) {
    return new Promise((next, error) => {
      authAxios
        .post(
          `/customer/department/report`,
          { ...filter },
          { params: {}, ...getToken(), responseType: "blob" }
        )
        .then((d) => {
          const blob = new Blob([d.data], { type: "application/vnd.ms-excel" });
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.download = `department-report-history.xls`; // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href);
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  fetchCustomerCallerHis(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/customer/createdcaller/views", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  downloadCustomerCallerHis(filter) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/createdcaller/report`, { ...filter }, getToken())
        .then((d) => {
          const linkSource =
            "data:" +
            `application/${ExcelDownloadFormat}` +
            ";base64, " +
            d?.data?.data;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = `customers-caller-history.${ExcelDownloadFormat}`;
          downloadLink.click();
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  fetchPhoneNumberHistory(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/customer/number/view", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  downloadPhoneNumberHistory(filter) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/number/report`, { ...filter }, getToken())
        .then((d) => {
          const linkSource =
            "data:" +
            `application/${ExcelDownloadFormat}` +
            ";base64, " +
            d?.data?.data;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = `customer-number-history.${ExcelDownloadFormat}`;
          downloadLink.click();
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  fetchCustomDIDHistory(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/customer/custom/did/list", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  downloadCustomDIDHistory(filter) {
    return new Promise((next, error) => {
      authAxios
        .post(`/customer/custom/did/report`, { ...filter }, getToken())
        .then((d) => {
          const linkSource =
            "data:" +
            `application/${ExcelDownloadFormat}` +
            ";base64, " +
            d?.data?.data;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = `custom-DID-history.${ExcelDownloadFormat}`;
          downloadLink.click();
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
}

export default new Request();
