import { useState, lazy, useEffect } from "react";
import {
  Card,
  Modal,
  Tooltip,
  notification,
  Radio,
  message,
  Table,
  Tabs,
  Button,
  Input,
  Tag,
  Switch,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import FilterTable from "../../components/Table";
import {
  OrderedListOutlined,
  AudioOutlined,
  CustomerServiceOutlined,
  SearchOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
} from "@ant-design/icons";

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [plansNaddonsPermissions, setPlansNaddonsPermissions] = useState("");
  const [loading, setLoader] = useState(false);
  const [deptId, setDeptId] = useState("");

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    currentUser?.message.find((key, val) => {
      if (key.permissionType == "Plans & Addons") {
        setPlansNaddonsPermissions(key);
      }
    });
  }, []);

  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.getAllAdOns();
    setLoader(false);
    if (success && data.length) {
      setDataSource(data);
    } else {
      setDataSource([]);

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  };

  const deleteAddOn = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } = await Request.deleteAdOns(
          fields
        );
        setLoader(false);
        apiFunction();
        if (success) {
          notification.success({
            message: message || "Failed",
          });
        } else {
          notification.error({
            message: message || "Failed",
            description: JSON.stringify(data)
              .replace("[", "")
              .replace("{", "")
              .replace("]", "")
              .replace(",", "")
              .replace("}", ""),
          });
        }
      },
    });
  };

  const onChange = async (val, id) => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.updateAdOns(
      id,
      { status: val }
    );
    setLoader(false);
    if (success) {
      notification.success({
        message: message || "success",
      });
      apiFunction();
    } else {
      notification.error({
        message: message || "Failed",
      });
    }
  };
  const editAddOn = (record) => {
    dispatch(getPushPathWrapper("editAddOns", record));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // fixed: "left",
      width: 250,
    },
    {
      title: "Monthly Charge",
      dataIndex: "monthprice",
      key: "monthprice",
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Active",
      dataIndex: "status",
      key: "status",
      render: (val, record) => {
        return (
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            checked={val}
            onChange={(e) => {
              plansNaddonsPermissions?.updateStatus && onChange(e, record?.id);
            }}
          />
        );
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      fixed: "right",
      render: (val, record) => {
        return (
          <div className="flex">
            {plansNaddonsPermissions?.deleteStatus ? (
              <div>
                <Tooltip title="Delete AddOn">
                  <DeleteOutlined
                    style={{ color: "red", fontSize: 20 }}
                    onClick={() => deleteAddOn(record.id)}
                  />
                </Tooltip>
              </div>
            ) : (
              <></>
            )}
            {plansNaddonsPermissions?.updateStatus ? (
              <div style={{ marginLeft: 10 }}>
                <Tooltip title="Edit AddOn">
                  <EditOutlined
                    style={{ color: "primary", fontSize: 20 }}
                    onClick={() => editAddOn(record)}
                  />
                </Tooltip>
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    apiFunction();
  }, [plansNaddonsPermissions?.listStatus]);
  return (
    <div>
      <div className="flex jcsb marginRight">
        <div></div>
        {plansNaddonsPermissions?.createStatus ? (
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            onClick={() => dispatch(getPushPathWrapper("Plans.addAddOns"))}
          >
            Add AddOn Service
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="marginTop">
        {plansNaddonsPermissions?.listStatus && (
          <FilterTable
            bordered
            dataSource={dataSource}
            size="large"
            columns={columns}
            loading={loading}
            pagination={false}
            scroll={{ x: 'auto' }}
          />
        )}
      </div>
    </div>
  );
};

export default HomeView;
