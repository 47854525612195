import { useState, useEffect } from "react";
import {
  Tooltip,
  notification,
  Button,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import FilterTable from "../../components/Table";
import {
  OrderedListOutlined,
  AudioOutlined,
  CustomerServiceOutlined,
  SearchOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
} from "@ant-design/icons";

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));
  const [plansNaddonsPermissions, setPlansNaddonsPermissions] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoader] = useState(false);
  const [deptId, setDeptId] = useState("");

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    currentUser?.message.find((key, val) => {
      if (key.permissionType == "Plans & Addons") {
        setPlansNaddonsPermissions(key);
      }
    });
  }, []);

  const deleteQueue = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete Queue",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } = await Request.deleteQueue(
          { department: fields?.id }
        );
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "success",
          });
          apiFunction();
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };
  const setShowModalOfProps = (filter) => {
    setShowModal(filter);

    apiFunction();
  };
  const setShowModalFxn = (record) => {
    setShowModal(true);
    setDeptId(record);

  };
  const deletePlan = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } = await Request.deletePlans(
          fields?.id
        );
        setLoader(false);
        apiFunction();
        if (success) {
          notification.success({
            message: message || "Failed",
          });
        } else {
          notification.error({
            message: message || "Failed",
            description: JSON.stringify(data)
              .replace("[", "")
              .replace("{", "")
              .replace("]", "")
              .replace(",", "")
              .replace("}", ""),
          });
        }
      },
    });
  };
  const edit = (record) => {
    dispatch(getPushPathWrapper("editPlan", record));
  };
  const columns = [
    {
      title: "Plan Name",
      dataIndex: "planname",
      width: 150,
    },

    {
      title: "Description",
      dataIndex: "plandescription",
      width: 150,
    },
    {
      title: "Month Price",
      dataIndex: "monthprice",
      width: 100,
    },
    {
      title: "Year Price",
      dataIndex: "yearprice",
      width: 100,
    },
    {
      title: "Free Minutes",
      dataIndex: "freeminutes",
      width: 100,
    },
    {
      title: "Data Storage",
      dataIndex: "datastorage",
      width: 100,
    },
    {
      title: "Maximum Users",
      dataIndex: "maximumallowedusers",
      width: 100,
    },

    {
      title: "Free Numbers",
      dataIndex: "freenumbers",
      width: 100,
    },

    // {
    //   title: 'After Hour Routing',
    //   dataIndex: "afterhourrouting",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Agent Blocking',
    //   dataIndex: "agentblocking",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Automatic Voicemail',
    //   dataIndex: "automaticvoicemail",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Call Barging',
    //   dataIndex: "callbarging",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Call Blocking',
    //   dataIndex: "callblocking",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Call Conference',
    //   dataIndex: "callconference"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Call Costlimit',
    //   dataIndex: "callcostlimit"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Callholdwithmusic',
    //   dataIndex: "callholdwithmusic"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Calllogs',
    //   dataIndex: "calllogs"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Callnotes',
    //   dataIndex: "callnotes"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Call Priority',
    //   dataIndex: "callpriority"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Call Queues',
    //   dataIndex: "callqueues"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Call Recording',
    //   dataIndex: "callrecording"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Recording Storage',
    //   dataIndex: "callrecordingstorage"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Call Reminder',
    //   dataIndex: "callreminder"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Call Scheduling',
    //   dataIndex: "callscheduling"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Call Whispering',
    //   dataIndex: "callwhispering"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Carrier Switch',
    //   dataIndex: "carrierswitch"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Cdr Report',
    //   dataIndex: "cdrreport"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'CLI',
    //   dataIndex: "cli"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Concurrent Calls',
    //   dataIndex: "concurrentcalls"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Contact',
    //   dataIndex: "contact"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Country Blocking',
    //   dataIndex: "countryblocking"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Custom Carriersetup',
    //   dataIndex: "customcarriersetup"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Customreport',
    //   dataIndex: "customreport"
    //  ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Cstm Welcome Msg',
    //   dataIndex: "customwelcomemessage"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Dashboard',

    //   dataIndex: "dashboard"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Defaultcli',

    //   dataIndex: "defaultcli"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Default Country Stg',

    //   dataIndex: "defaultcountrysetting"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Delete Calllog',

    //   dataIndex: "deletecalllog"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Departments',

    //   dataIndex: "departments"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'DNC',

    //   dataIndex: "dnc"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Mail for Voicemail',

    //   dataIndex: "emailnotificationforvoicemail"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Forward to Device',

    //   dataIndex: "forwardtodevice"
    //   ,
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Fwd to Mtple Device',
    //   dataIndex: "forwardtomultipledevice",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },

    // {
    //   title: 'Global Contact',
    //   dataIndex: "globalcontact",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Holiday Routing',
    //   dataIndex: "holidayrouting",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Intelligent Reporting',
    //   dataIndex: "intelligentreporting",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Internal Team Calling',
    //   dataIndex: "internalteamcalling",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'IP Whitelisting',
    //   dataIndex: "ipwhitelisting",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Last Callsummary',
    //   dataIndex: "lastcallsummary",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Mobileapp',
    //   dataIndex: "mobileapp",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Post Call Survey',
    //   dataIndex: "postcallsurvey",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Power Dialer',
    //   dataIndex: "powerdialer",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Reports on Email',
    //   dataIndex: "reportsonemail",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'SMS',
    //   dataIndex: "sms",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Two Factor Auth',
    //   dataIndex: "twofactorauthentication",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Unlimited Calls',
    //   dataIndex: "unlimitedcalls",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Usr Right Cstmization',
    //   dataIndex: "userrightscustomization",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Voicemail Ctm Greeting',
    //   dataIndex: "voicemailwithcustomgreeting",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Warm, Blind Transfer',
    //   dataIndex: "warmandblindtransfer",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    // {
    //   title: 'Working Hours',
    //   dataIndex: "workinghours",
    //   render: (val, r) => {
    //     return <Tag style={{ borderRadius: 30, width: 60, justifyContent: "center" }} color={val ? '#2fd838' : "#f50"}>{val ? 'Yes' : 'No'}</Tag>
    //   }
    // },
    {
      title: "Active",
      dataIndex: "active",
      render: (val, r) => {
        return (
          <Tag
            style={{ borderRadius: 30, width: 60, justifyContent: "center", margin: "auto" }}
            color={val ? "#2fd838" : "#f50"}
          >
            {val ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdon",
      key: "createdon",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdon - b.createdon,
      render: (val, r) => {
        return <Tag>{moment.utc(val).format("DD-MM-YYYY")}</Tag>;
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      fixed: "right",
      render: (val, record) => {
        return (
          <div className="flex">
            {plansNaddonsPermissions?.deleteStatus ? (
              <div>
                <Tooltip title="Delete Plan">
                  <DeleteOutlined
                    style={{ color: "red", fontSize: 20 }}
                    onClick={() => deletePlan(record)}
                  />
                </Tooltip>
              </div>
            ) : (
              <></>
            )}
            {plansNaddonsPermissions?.updateStatus ? (
              <div style={{ marginLeft: 10 }}>
                <Tooltip title="Edit Plan">
                  <EditOutlined
                    style={{ color: "primary", fontSize: 20 }}
                    onClick={() => edit(record)}
                  />
                </Tooltip>
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];
  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.getAllPlans();
    setLoader(false);
    if (success && data.length) {
      setDataSource(data);
    } else {
      setDataSource([]);

      // notification.error({
      //   message: message || "Failed"
      // })
    }
  };
  useEffect(() => {
    apiFunction();
  }, [plansNaddonsPermissions?.listStatus]);
  return (
    <div>
      <div className="flex jcsb marginRight">
        <div></div>
        {plansNaddonsPermissions?.createStatus ? (
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            onClick={() => dispatch(getPushPathWrapper("Plans.addPlan"))}
          >
            Add Plan
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="marginTop">
        {plansNaddonsPermissions?.listStatus && (
          <FilterTable
            bordered
            dataSource={dataSource}
            scroll={{ x: "auto" }}
            size="large"
            columns={columns}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default HomeView;
